import type { VariableType } from "@prisma/client";

export enum ConditionType {
	AND = "AND",
	OR = "OR",
}

export const AllConditionOperators = {
	GREATER_THAN: ">",
	LESS_THAN: "<",
	EQUALS: "=",
	IS_AFTER: "isAfter",
	IS_BEFORE: "isBefore",
	IS_EQUAL: "isEqual",
	IS_EQUAL_OR_AFTER: "isEqualOrAfter",
	IS_EQUAL_OR_BEFORE: "isEqualOrBefore",
} as const;

export type AnyConditionOperator =
	(typeof AllConditionOperators)[keyof typeof AllConditionOperators];

export type ConditionOperator<T extends VariableType = VariableType> =
	T extends "STRING" | "ENUM"
		? StringOrEnumConditionOperator
		: T extends "NUMBER"
			? NumberConditionOperator
			: T extends "BOOLEAN"
				? BooleanConditionOperator
				: T extends "DATE"
					? DateConditionOperator
					: never;

export const StringOrEnumConditionOperator = {
	EQUALS: AllConditionOperators.EQUALS,
} as const;

export const NumberConditionOperator = {
	GREATER_THAN: AllConditionOperators.GREATER_THAN,
	LESS_THAN: AllConditionOperators.LESS_THAN,
	EQUALS: AllConditionOperators.EQUALS,
} as const;

export const BooleanConditionOperator = {
	EQUALS: AllConditionOperators.EQUALS,
} as const;

export const DateConditionOperator = {
	IS_AFTER: AllConditionOperators.IS_AFTER,
	IS_BEFORE: AllConditionOperators.IS_BEFORE,
	IS_EQUAL: AllConditionOperators.IS_EQUAL,
	IS_EQUAL_OR_AFTER: AllConditionOperators.IS_EQUAL_OR_AFTER,
	IS_EQUAL_OR_BEFORE: AllConditionOperators.IS_EQUAL_OR_BEFORE,
} as const;

export type StringOrEnumConditionOperator =
	(typeof StringOrEnumConditionOperator)[keyof typeof StringOrEnumConditionOperator];

export type NumberConditionOperator =
	(typeof NumberConditionOperator)[keyof typeof NumberConditionOperator];

export type BooleanConditionOperator =
	(typeof BooleanConditionOperator)[keyof typeof BooleanConditionOperator];

export type DateConditionOperator =
	(typeof DateConditionOperator)[keyof typeof DateConditionOperator];
