import { VariableType } from "@prisma/client";
import { z } from "zod";

import { isNullOrUndefined, isNumber } from "@repos/rate-resolver-shared";

export const enumValueSchema = z.object({
	value: z.string().min(3, "Veuillez entrer au moins trois caractères"),
	label: z.string().min(3, "Veuillez entrer au moins trois caractères"),
});

export type EnumValue = z.infer<typeof enumValueSchema>;

//TODO: fix union error message
export const valueSchema = z
	.string()
	.min(1, "Veuillez entrer au moins un caractère")
	.or(z.number())
	.or(z.boolean())
	.or(z.date())
	.nullable()
	.transform((value) => {
		const isValueNumber = isNumber(value);
		if (isValueNumber && typeof value === "string") {
			return parseFloat(value);
		}

		if (value === "true" || value === "false") {
			return Boolean(value);
		}

		if (!isValueNumber && typeof value === "string") {
			const parsedDate = new Date(value);
			if (!isNaN(parsedDate.getTime())) {
				return parsedDate;
			}
		}

		return value;
	});

export const keySchema = z
	.string()
	.min(3, "Veuillez entrer au moins trois caractères");

export const isVariableValueValid = ({
	value,
	acceptNullOrUndefined = true,
	type,
}: {
	value: string | number | boolean | Date | null | undefined;
	acceptNullOrUndefined?: boolean;
	type: VariableType;
}) => {
	if (isNullOrUndefined(value)) {
		return acceptNullOrUndefined;
	}

	let valid = false;
	switch (type) {
		case VariableType.STRING:
			valid = z.string().safeParse(value).success;
			break;
		case VariableType.NUMBER:
			valid = z.coerce.number().safeParse(value).success;
			break;
		case VariableType.BOOLEAN:
			valid = z.coerce.boolean().safeParse(value).success;
			break;
		case VariableType.DATE:
			valid = z
				.union([z.string().datetime(), z.date()])
				.safeParse(value).success;
			break;
		case VariableType.ENUM:
			valid = z.string().safeParse(value).success;
			break;
	}

	return valid;
};
