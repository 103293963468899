import type { Step } from "@/types";
import type { Resolver } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Sortable } from "@/components/ui/sortable";
import { getDefaultOperation } from "@/lib";
import { trpc } from "@/utils";
import { closestCorners } from "@dnd-kit/core";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusIcon } from "@radix-ui/react-icons";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";

import type { SubStepDTO, SubStepsUpsertDTO } from "@repos/rate-resolver-dtos";
import { subStepsUpsertSchema } from "@repos/rate-resolver-dtos";
import { ConditionType, isNullOrUndefined } from "@repos/rate-resolver-shared";

import { SubmitButton } from "../shared/SubmitButton";
import { SubStepItem } from "./SubStepItem";

type SubStepFormProps = {
	initialSubSteps: SubStepDTO[];
	step: Step;
};
const defaultSubStep = () => {
	return {
		label: `Étape 1`,
		order: 1,
		id: uuidv4(),
		conditions: [{ id: uuidv4() }] as SubStepDTO["conditions"],
		operations: [getDefaultOperation()] as SubStepDTO["operations"],
		fallbackOperations: [
			getDefaultOperation(),
		] as SubStepDTO["fallbackOperations"],
		conditionType: ConditionType.AND,
	};
};

// Custom resolver to filter out empty operands before validation
const subStepUpsertResolver: Resolver<SubStepsUpsertDTO> = async (
	values,
	context,
	options,
) => {
	// Filter out empty operands in each subStep's operations / fallbackOperations
	const filteredValues = {
		...values,
		subSteps: values.subSteps.map((subStep) => ({
			...subStep,
			operations: subStep.operations.map((operation) => ({
				...operation,
				operands: operation.operands.filter(
					(operand, index) => index <= 1 || !isNullOrUndefined(operand.value),
				),
			})),
			fallbackOperations: subStep.fallbackOperations.map((operation) => ({
				...operation,
				operands: operation.operands.filter(
					(operand, index) => index <= 1 || !isNullOrUndefined(operand.value),
				),
			})),
		})),
	};

	// Pass the filtered values to zodResolver for validation
	return zodResolver(subStepsUpsertSchema)(filteredValues, context, options);
};

export const SubStepForm = ({ initialSubSteps, step }: SubStepFormProps) => {
	const navigate = useNavigate();

	const form = useForm<SubStepsUpsertDTO>({
		resolver: subStepUpsertResolver,
		defaultValues: {
			stepId: step.id,
			subSteps:
				initialSubSteps.length > 0 ? initialSubSteps : [defaultSubStep()],
		},
		mode: "onSubmit",
	});

	const { mutate, isLoading } = trpc.subSteps.upsertSubSteps.useMutation({
		onSuccess: () => {
			toast.success("Étape mise à jour avec succès!", {
				action: {
					label: "Étapes",
					onClick: () => navigate("/projet/etapes"),
				},
			});
		},
	});

	const { fields, append, replace, remove } = useFieldArray({
		name: "subSteps",
		control: form.control,
	});

	const handleReorder = (newOrder: SubStepsUpsertDTO["subSteps"]) => {
		const updatedSubSteps = newOrder.map((item, index) => ({
			...item,
			order: index + 1, // Ensure order starts from 1
		}));
		replace(updatedSubSteps);
	};

	const onSubmit = (data: SubStepsUpsertDTO) => {
		mutate(data);
	};
	const onError = (errors: any) => {
		console.log("Error", errors);
	};

	const handleAddSubStep = () => {
		const newSubStepData: SubStepsUpsertDTO["subSteps"][0] = {
			label: `Étape ${fields.length + 1}`,
			order: fields.length + 1,
			id: uuidv4(),
			conditions: [{ id: uuidv4() }] as SubStepDTO["conditions"],
			operations: [getDefaultOperation()],
			fallbackOperations: [getDefaultOperation()],
			conditionType: ConditionType.AND,
		};
		append(newSubStepData);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit, onError)}>
				<Sortable
					orientation="mixed"
					collisionDetection={closestCorners}
					value={fields}
					onValueChange={handleReorder}
					overlay={<div className="size-full rounded-md bg-card/10" />}
				>
					<div className="space-y-4">
						{fields.map((subStep, index) => (
							<SubStepItem
								subStep={subStep}
								key={subStep.id}
								remove={remove}
								index={index}
							/>
						))}
					</div>
				</Sortable>
				<div className="mt-4 flex items-center justify-between">
					<Button
						variant="default"
						type="button"
						className="flex items-center space-x-2"
						onClick={handleAddSubStep}
					>
						<PlusIcon className="h-4 w-4" />
						<span className="font-medium">Ajouter une règle</span>
					</Button>
					<SubmitButton isLoading={isLoading} label="Enregistrer" />
				</div>
			</form>
		</FormProvider>
	);
};
