import { useState } from "react";
import { SpreadSheetForm } from "@/components/enumImporter";
import { CustomSelect } from "@/components/shared/CustomSelect";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import * as XLSX from "xlsx";

import type { EnumValue } from "@repos/rate-resolver-dtos";

interface EnumImportDialogProps {
	isModalOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	onImport: (options: EnumValue[]) => void;
}

export const EnumImportDialog = ({
	isModalOpen,
	setIsModalOpen,
	onImport,
}: EnumImportDialogProps) => {
	const [fileData, setFileData] = useState<XLSX.WorkBook | null>(null);
	const [workbookNames, setWorkbookNames] = useState<string[]>([]);
	const [selectedWorkbook, setSelectedWorkbook] = useState<string | null>(null);

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = (e) => {
			if (e.target) {
				const data = new Uint8Array(e.target.result as ArrayBuffer);
				const workbook = XLSX.read(data, { type: "array" });
				setFileData(workbook);
				setWorkbookNames(workbook.SheetNames);
			}
		};

		reader.readAsArrayBuffer(file);
	};

	return (
		<Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
			<DialogContent className="flex h-[80vh] max-h-[80vh] max-w-[80vw] flex-col">
				<DialogHeader>
					<DialogTitle>Importer options variable</DialogTitle>
				</DialogHeader>

				<div className="h-full w-full space-y-4">
					<input
						id="file"
						type="file"
						accept=".xlsx, .xls"
						onChange={handleFileUpload}
						className="block w-full text-sm text-gray-500 file:mr-4 file:rounded-full file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-sm file:font-semibold file:text-blue-700 hover:file:bg-blue-100"
					/>

					{workbookNames.length > 0 && (
						<div className="flex flex-col gap-2">
							<Label>Choix Classeur</Label>
							<CustomSelect
								options={workbookNames.map((name) => ({
									label: name,
									value: name,
								}))}
								placeholder="Classeurs"
								value={selectedWorkbook ?? ""}
								onValueChange={setSelectedWorkbook}
							/>
						</div>
					)}
					<SpreadSheetForm
						workbook={fileData}
						selectedSheet={selectedWorkbook}
						onImport={onImport}
					/>
				</div>
			</DialogContent>
		</Dialog>
	);
};
