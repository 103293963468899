import { useEffect, useState } from "react";
import { ConditionBuilder } from "@/components/subStep/conditionBuilder/ConditionBuilder";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Label } from "@/components/ui/label";
import {
	Sortable,
	SortableDragHandle,
	SortableItem,
} from "@/components/ui/sortable";
import { useCopyPasteActions } from "@/hooks/useCopyPaste";
import { getDefaultOperation } from "@/lib";
import { pointerWithin } from "@dnd-kit/core";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { ChevronDown, ChevronUp, Plus, Trash2 } from "lucide-react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { z } from "zod";

import type { Operation, SubStepsUpsertDTO } from "@repos/rate-resolver-dtos";
import { operationSchema } from "@repos/rate-resolver-dtos";

import { CopyPasteActions } from "../shared/CopyPasteActions";
import { OperationBuilder } from "./operationBuilder/OperationBuilder";

export type SubStepItemProps = {
	remove: (index: number) => void;
	index: number;
	subStep: SubStepsUpsertDTO["subSteps"][0] & { id: string };
};
export function SubStepItem({ remove, index, subStep }: SubStepItemProps) {
	const [isOpen, setIsOpen] = useState(true);
	const [isFallbackValid, setIsFallbackValid] = useState(false);
	const [isOperationValid, setIsOperationValid] = useState(false);

	const form = useFormContext<SubStepsUpsertDTO>();

	const {
		fields: operations,
		append: appendOperation,
		remove: removeOperation,
		move: moveOperations,
		replace: replaceOperations,
	} = useFieldArray<SubStepsUpsertDTO>({
		control: form.control,
		name: `subSteps.${index}.operations`,
	});

	const {
		fields: fallbackOperations,
		append: appendFallbackOperation,
		remove: removeFallbackOperation,
		move: moveFallbackOperations,
		replace: replaceFallbackOperations,
	} = useFieldArray<SubStepsUpsertDTO>({
		control: form.control,
		name: `subSteps.${index}.fallbackOperations`,
	});

	const fallbackWatch = useWatch({
		control: form.control,
		name: `subSteps.${index}.fallbackOperations`,
		defaultValue: [],
	});

	const operationWatch = useWatch({
		control: form.control,
		name: `subSteps.${index}.operations`,
		defaultValue: [],
	});

	const addOperation = () => {
		appendOperation(getDefaultOperation());
	};

	const addFallbackOperation = () => {
		appendFallbackOperation(getDefaultOperation());
	};

	const {
		handleCopy: handleCopyOperations,
		handleReplace: handleReplaceOperations,
		handleAppend: handleAppendOperations,
		copiedItems: copiedOperations,
	} = useCopyPasteActions({
		items: operationWatch,
		itemType: "operations",
		appendAction: appendOperation,
		replaceAction: replaceOperations,
	});

	const {
		handleCopy: handleCopyFallbacks,
		handleReplace: handleReplaceFallbacks,
		handleAppend: handleAppendFallbacks,
		copiedItems: copiedFallbackOperations,
	} = useCopyPasteActions({
		items: fallbackWatch,
		itemType: "operations",
		appendAction: appendFallbackOperation,
		replaceAction: replaceFallbackOperations,
	});

	useEffect(() => {
		const validationOperationsResult = z
			.array(operationSchema)
			.safeParse(operationWatch);
		const validationFallbacksResult = z
			.array(operationSchema)
			.safeParse(fallbackWatch);

		setIsOperationValid(validationOperationsResult.success);
		setIsFallbackValid(validationFallbacksResult.success);
	}, [operationWatch, fallbackWatch]);

	return (
		<SortableItem key={subStep.id} value={subStep.id} asChild>
			<Card className="p-4">
				<Collapsible open={isOpen} onOpenChange={setIsOpen}>
					<div className="flex items-center justify-between">
						{subStep.label} + {subStep.order}
						<div className="flex items-center justify-end gap-2 space-x-2">
							<CollapsibleTrigger className="text-lg font-medium">
								<Button variant="link" size="icon" className="w-9 p-0">
									{isOpen ? (
										<ChevronUp className="h-4 w-4" />
									) : (
										<ChevronDown className="h-4 w-4" />
									)}
									<span className="sr-only">Toggle</span>
								</Button>
							</CollapsibleTrigger>
							<Button
								variant={"ghost"}
								size={"icon"}
								type={"button"}
								className="hover:bg-destructive-foreground"
								onClick={() => remove(index)}
							>
								<Trash2 className="size-4 text-destructive" />
							</Button>
							<SortableDragHandle
								variant="default"
								size="icon"
								className="size-8"
							>
								<DragHandleDots2Icon className="size-4" aria-hidden="true" />
							</SortableDragHandle>
						</div>
					</div>
					<CollapsibleContent className="mt-4">
						<ConditionBuilder subStepIndex={index} />
						<div className="mb-4 w-full rounded-xl border border-gray-300 bg-card p-4 text-card-foreground shadow">
							<div className="flex w-full flex-row justify-end">
								<CopyPasteActions
									isValid={isOperationValid}
									hasFields={operations.length > 0}
									copiedItems={
										Array.isArray(copiedOperations) &&
										copiedOperations.length > 0
									}
									onCopy={handleCopyOperations}
									onReplace={handleReplaceOperations}
									onAppend={handleAppendOperations}
								/>
							</div>
							<Label className="text-sm font-medium">
								Si la condition est vraie faire:
							</Label>
							<Sortable
								orientation="mixed"
								collisionDetection={pointerWithin}
								value={operations}
								onMove={({ activeIndex, overIndex }) =>
									moveOperations(activeIndex, overIndex)
								}
								overlay={<div className="size-full rounded-md bg-card/10" />}
							>
								{operations.map((operation, indexOperations) => (
									<OperationBuilder
										key={operation.id}
										operation={operation as Operation}
										operationIndex={indexOperations}
										basePath={`subSteps.${index}.operations`}
										removeOperation={removeOperation}
									/>
								))}
							</Sortable>
							<div className="mt-4 flex justify-end">
								<Button
									variant="outline"
									size="sm"
									type="button"
									onClick={addOperation}
								>
									<Plus className="mr-2 h-4 w-4" />
									Ajouter une opération
								</Button>
							</div>
						</div>
						<div className="mb-4 w-full rounded-xl border border-gray-300 bg-card p-4 text-card-foreground shadow">
							<div className="flex w-full flex-row items-end justify-end">
								<CopyPasteActions
									isValid={isFallbackValid}
									hasFields={operations.length > 0}
									copiedItems={
										Array.isArray(copiedFallbackOperations) &&
										copiedFallbackOperations.length > 0
									}
									onCopy={handleCopyFallbacks}
									onReplace={handleReplaceFallbacks}
									onAppend={handleAppendFallbacks}
								/>
							</div>
							<Label className="text-sm font-medium">Si non:</Label>
							<Sortable
								orientation="mixed"
								collisionDetection={pointerWithin}
								value={fallbackOperations}
								onMove={({ activeIndex, overIndex }) =>
									moveFallbackOperations(activeIndex, overIndex)
								}
								overlay={<div className="size-full rounded-md bg-card/10" />}
							>
								{fallbackOperations.map((fallback, indexFallback) => (
									<OperationBuilder
										key={fallback.id}
										operation={fallback as Operation}
										operationIndex={indexFallback}
										basePath={`subSteps.${index}.fallbackOperations`}
										removeOperation={removeFallbackOperation}
									/>
								))}
							</Sortable>
							<div className="mt-4 flex justify-end">
								<Button
									variant="outline"
									size="sm"
									type="button"
									onClick={addFallbackOperation}
								>
									<Plus className="mr-2 h-4 w-4" />
									Ajouter une opération
								</Button>
							</div>
						</div>
					</CollapsibleContent>
				</Collapsible>
			</Card>
		</SortableItem>
	);
}
