import type useOperationOptions from "@/hooks/useOperationOptions";
import type { Path } from "react-hook-form";
import { CustomSelect } from "@/components/shared/CustomSelect";
import { FormField, FormItem } from "@/components/ui/form";

import type {
	SubStepsUpsertDTO,
	UpdateFinalStepDTO,
} from "@repos/rate-resolver-dtos";

type OperatorProps = {
	operationIndex: number;
	operationsOptions: ReturnType<typeof useOperationOptions>;
	basePath: Path<SubStepsUpsertDTO> | Path<UpdateFinalStepDTO>;
};
export const Operator = ({
	operationIndex,
	basePath,
	operationsOptions: { disabled, allowedOperations },
}: OperatorProps) => {
	return (
		<FormField
			name={`${basePath}.${operationIndex}.operationType`}
			render={({ field }) => (
				<FormItem>
					<CustomSelect
						placeholder="Selectionner un opérateur"
						options={allowedOperations.map(({ label, key }) => ({
							label,
							value: key,
						}))}
						value={field.value}
						onValueChange={(value) => {
							if (value && value.length > 0 && value !== field.value) {
								field.onChange(value);
							}
						}}
						className="h-auto w-40 border-slate-200 bg-slate-200 dark:border dark:border-input dark:bg-transparent"
						disabled={disabled}
						isForm
					/>
				</FormItem>
			)}
		/>
	);
};
