import { ConditionType } from "@prisma/client";
import { z } from "zod";

import { conditionSchema, operationSchema } from "../primitives";

export const subStepResponseSchema = z.object({
	id: z.string(),
	label: z.string(),
	order: z.number(),
	conditionType: z.nativeEnum(ConditionType).default(ConditionType.AND),
	conditions: z.array(conditionSchema).default([]),
	operations: z.array(operationSchema).default([]),
	fallbackOperations: z.array(operationSchema).default([]),
});

export type SubStepResponse = z.infer<typeof subStepResponseSchema>;
