import type { Path, UseFieldArrayRemove } from "react-hook-form";
import { useState } from "react";
import { useAppStore } from "@/stores/useAppStore";
import { useFormContext, useWatch } from "react-hook-form";

import type {
	Operand as OperandDTO,
	SubStepsUpsertDTO,
	UpdateFinalStepDTO,
} from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { isNullOrUndefined, OperandType } from "@repos/rate-resolver-shared";

import { VariableSelect } from "../variableSelect/VariableSelect";

interface OperandProps {
	basePath: Path<SubStepsUpsertDTO> | Path<UpdateFinalStepDTO>;
	operand: OperandDTO;
	operandIndex: number;
	operationIndex: number;
	assignedVariableType: SimpleVariable["type"] | null | undefined;
	removeOperation: UseFieldArrayRemove;
	totalOperands: number;
	isOperandDisabled: boolean;
	allowedVariableTypes: SimpleVariable["type"][];
	resetOperands: () => void;
}

export const Operand = ({
	operand,
	operandIndex,
	operationIndex,
	removeOperation,
	totalOperands,
	isOperandDisabled,
	allowedVariableTypes,
	resetOperands,
	assignedVariableType,
	basePath,
}: OperandProps) => {
	const { initialOperand } = useAppStore((state) => ({
		initialOperand: state.getVariableByKey(
			typeof operand.value === "string" ? operand.value : undefined,
		),
	}));
	const [selectedOperand, setSelectedOperand] = useState<
		SimpleVariable | null | undefined
	>(initialOperand);

	const form = useFormContext<SubStepsUpsertDTO>();

	const rawValue = useWatch({
		control: form.control,
		name: `${basePath}.${operationIndex}.operands.${operandIndex}.value` as Path<SubStepsUpsertDTO>,
		defaultValue: undefined,
	}) as OperandDTO["value"] | undefined;

	const rawType = useWatch({
		control: form.control,
		name: `${basePath}.${operationIndex}.operands.${operandIndex}.type` as Path<SubStepsUpsertDTO>,
		defaultValue: undefined,
	}) as OperandDTO["type"] | undefined;

	return (
		<VariableSelect
			variableTypes={allowedVariableTypes}
			onSelect={(variable) => {
				const prevOperandType = selectedOperand?.type;
				setSelectedOperand(variable);
				if (
					!isNullOrUndefined(prevOperandType) &&
					operandIndex === 0 &&
					prevOperandType !== variable.type &&
					totalOperands !== 1
				) {
					const indexesToRemove = Array.from(
						{ length: totalOperands - 1 },
						(_, i) => i + 1,
					);
					removeOperation(indexesToRemove);
					form.setValue(
						`${basePath}.${operationIndex}.operationType` as Path<SubStepsUpsertDTO>,
						undefined,
					);
				}
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.type` as Path<SubStepsUpsertDTO>,
					OperandType.FIELD,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.value` as Path<SubStepsUpsertDTO>,
					variable.key,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.variableType` as Path<SubStepsUpsertDTO>,
					variable.type,
				);
			}}
			selectedItem={selectedOperand}
			allowRawValue={operandIndex !== 0}
			onRawValueSubmit={(value) => {
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.type` as Path<SubStepsUpsertDTO>,
					OperandType.VALUE,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.value` as Path<SubStepsUpsertDTO>,
					value as string,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.variableType` as Path<SubStepsUpsertDTO>,

					assignedVariableType ?? undefined,
				);
				setSelectedOperand(null);
			}}
			rawValue={rawType === OperandType.VALUE ? rawValue : undefined}
			disabled={isOperandDisabled}
			onClear={() => {
				if (operandIndex === 0 && totalOperands <= 1) {
					resetOperands();
					form.setValue(
						`${basePath}.${operationIndex}.operationType` as Path<SubStepsUpsertDTO>,
						undefined,
					);
				} else {
					removeOperation(operandIndex);
				}
			}}
		/>
	);
};
