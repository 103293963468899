import type { DataTableAccessoryProps } from "@/components/dataTable/data-table";

import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Popover, PopoverTrigger } from "../ui/popover";

function DataTableAccessory<T>({
	ActionComponent,
	actionComponentProps,
	buttonText,
	onChangeSearchValue,
	searchValue,
	buttonIcon,
}: DataTableAccessoryProps<T>) {
	return (
		<div className="flex items-center justify-between py-4">
			<Input
				placeholder="Recherche variable..."
				value={searchValue}
				onChange={(e) => onChangeSearchValue(e.target.value)}
				className="max-w-sm"
			/>

			<Popover modal={true}>
				<PopoverTrigger asChild>
					<Button>
						{buttonIcon}
						{buttonText}
					</Button>
				</PopoverTrigger>
				<ActionComponent {...actionComponentProps} />
			</Popover>
		</div>
	);
}

export default DataTableAccessory;
