import { useCallback, useEffect, useState } from "react";
import { CopyPasteActions } from "@/components/shared/CopyPasteActions";
import { ConditionRow } from "@/components/subStep/conditionBuilder/ConditionRow";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Sortable } from "@/components/ui/sortable";
import { useCopyPasteActions } from "@/hooks/useCopyPaste";
import { pointerWithin } from "@dnd-kit/core";
import { Plus } from "lucide-react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { z } from "zod";
import { v4 as uuidv4 } from "uuid";

import type { Condition, SubStepsUpsertDTO } from "@repos/rate-resolver-dtos";
import { conditionSchema } from "@repos/rate-resolver-dtos";

export type ConditionBuilderProps = {
	subStepIndex: number;
};

export const ConditionBuilder = ({ subStepIndex }: ConditionBuilderProps) => {
	const [isValid, setIsValid] = useState(false);

	const form = useFormContext<SubStepsUpsertDTO>();

	const { fields, append, remove, move, replace } = useFieldArray({
		control: form.control,
		name: `subSteps.${subStepIndex}.conditions`,
	});

	const shouldShowOperator = useCallback(
		(index: number) => {
			return index !== fields.length - 1;
		},
		[fields],
	);

	const conditionsWatch = useWatch({
		name: `subSteps.${subStepIndex}.conditions`,
		defaultValue: [],
	});

	const {
		handleCopy: handleCopyConditions,
		handleReplace: handleReplaceConditions,
		handleAppend: handleAppendConditions,
		copiedItems: copiedConditions,
	} = useCopyPasteActions({
		items: conditionsWatch as Condition[],
		itemType: "conditions",
		appendAction: append,
		replaceAction: (items) => replace(items),
	});

	useEffect(() => {
		const validationResult = z
			.array(conditionSchema)
			.safeParse(conditionsWatch);
		setIsValid(validationResult.success);
	}, [conditionsWatch]);

	return (
		<Sortable
			orientation="mixed"
			collisionDetection={pointerWithin}
			value={fields}
			onMove={({ activeIndex, overIndex }) => move(activeIndex, overIndex)}
			overlay={<div className="size-full rounded-md bg-card/10" />}
		>
			<Card className="mb-4 w-full border-gray-300 p-4">
				<div className="flex w-full flex-row justify-end">
					<CopyPasteActions
						isValid={isValid}
						hasFields={fields.length > 0}
						copiedItems={
							Array.isArray(copiedConditions) && copiedConditions.length > 0
						}
						onCopy={handleCopyConditions}
						onReplace={handleReplaceConditions}
						onAppend={handleAppendConditions}
					/>
				</div>
				<div className="flex w-full flex-1">
					<div className="flex w-full flex-1 flex-col gap-2">
						<span className="mr-4 mt-1">
							{fields.length > 0 ? "Si:" : "Toujours"}
						</span>
						{fields.map((condition, conditionIndex) => (
							<ConditionRow
								key={condition.id}
								conditionIndex={conditionIndex}
								subStepIndex={subStepIndex}
								condition={condition}
								onDelete={() => remove(conditionIndex)}
								shouldShowOperator={shouldShowOperator(conditionIndex)}
								control={form.control}
								setValue={form.setValue}
								errors={form.formState.errors}
							/>
						))}
					</div>
				</div>

				<div className="mt-4 flex justify-end gap-4">
					<Button
						variant="outline"
						type="button"
						size="sm"
						onClick={() => append({ id: uuidv4() } as Condition)}
					>
						<Plus className="mr-2 h-4 w-4" />
						Ajouter une condition
					</Button>
				</div>
			</Card>
		</Sortable>
	);
};
