/**
 * v0 by Vercel.
 * @see https://v0.dev/t/cZWqVaPoGSN
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import type { Step } from "@/types";
import { useEffect } from "react";
import { SubStepForm } from "@/components/subStep/SubStepForm";
import { SubStepTitle } from "@/components/subStep/SubStepTitle";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { Loader2 } from "lucide-react";

import type { SubStepsUpsertDTO } from "@repos/rate-resolver-dtos";

interface RulesProps {
	step: Step;
}

export default function Rules({ step }: RulesProps) {
	const {
		isLoading: isLoadingSubSteps,
		data: subSteps,
		isFetched: isSubStepsFetched,
	} = trpc.subSteps.getSubSteps.useQuery(
		{ stepId: step.id },
		{
			refetchOnReconnect: false,
			refetchOnMount: false,
			refetchOnWindowFocus: false,
		},
	);
	const { setSubSteps, projectId, setVariables } = useAppStore((state) => ({
		setSubSteps: state.setSelectedSubSteps,
		projectId: state.selectedProject?.id ?? "",
		setVariables: state.setSelectedVariables,
	}));
	const {
		data: variables,
		isFetched: isVariablesFetched,
		isLoading: isLoadingVariables,
	} = trpc.variables.getVariables.useQuery({
		projectId,
	});

	useEffect(() => {
		if (subSteps && isSubStepsFetched) {
			setSubSteps(subSteps as SubStepsUpsertDTO["subSteps"]);
		}
	}, [subSteps, isSubStepsFetched, setSubSteps]);

	useEffect(() => {
		if (variables && isVariablesFetched) {
			setVariables(variables);
		}
	}, [variables, isVariablesFetched, setVariables]);

	if (isLoadingSubSteps || isLoadingVariables) {
		return <Loader2 className="size-4 animate-spin" />;
	}

	return (
		<div className="p-6">
			<SubStepTitle step={step} />

			<h2 className="mb-4 text-lg font-semibold">Règles:</h2>

			{variables && subSteps ? (
				<SubStepForm
					initialSubSteps={subSteps as SubStepsUpsertDTO["subSteps"]}
					step={step}
				/>
			) : null}
		</div>
	);
}
