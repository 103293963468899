import { useAppStore } from "@/stores/useAppStore";
import * as Sentry from "@sentry/react";

import OuputVariables from "./components/OuputVariables";
import StepSummaryCard from "./components/StepSummaryCard";

function TarificationOutput() {
	const { tarificationSummary } = useAppStore((state) => ({
		tarificationSummary: state.tarificationSummary,
	}));

	return (
		<Sentry.ErrorBoundary
			fallback={({ error }) => (
				<div>
					Une erreur est survenue lors de la génération de la tarification
					veuillez réessayer
					{/*  eslint-disable-next-line turbo/no-undeclared-env-vars */}
					<span>{import.meta.env.MODE === "development" && error.message}</span>
				</div>
			)}
		>
			<div>
				{tarificationSummary && (
					<>
						<OuputVariables />
						{tarificationSummary.summary.stepSummaries.map((item, index) => (
							<StepSummaryCard
								key={`summ${index}`}
								stepSummary={item}
								stepNumber={index + 1}
							/>
						))}
					</>
				)}
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default TarificationOutput;
