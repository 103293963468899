import type { Step } from "@/types";
import { StepButton } from "@/components/StepButton";
import { Button } from "@/components/ui/button";
import { Sortable } from "@/components/ui/sortable";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { pointerWithin } from "@dnd-kit/core";
import { CirclePlus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import type { UpdateStepDTO } from "@repos/rate-resolver-dtos";

export const Steps = () => {
	const navigate = useNavigate();

	const { selectedProject, setCurrentStep } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
		setCurrentStep: state.setCurrentStep,
	}));

	const { data: stepsData, refetch: refetchSteps } =
		trpc.steps.getSteps.useQuery(
			{ projectId: selectedProject?.id ?? "" },
			{
				enabled: !!selectedProject,
			},
		);

	const addStepMutation = trpc.steps.createBlankStep.useMutation({
		onSuccess: () => refetchSteps(),
	});

	const deleteStepMutation = trpc.steps.deleteStep.useMutation({
		onSuccess: () => refetchSteps(),
	});

	const updateStepOrderMutation = trpc.steps.updateStepsOrder.useMutation({
		onSuccess: () => {
			refetchSteps();
			toast.success("Étapes mises à jour avec succès");
		},
		onError: (error) => {
			toast.error(`Échec de la mise à jour des étapes : ${error.message}`);
		},
	});

	const handleAddStep = () => {
		if (selectedProject) {
			addStepMutation.mutate({ projectId: selectedProject.id });
		}
	};

	const handleRemoveStep = (stepId: string) => {
		deleteStepMutation.mutate({ id: stepId });
	};

	const handleReorder = async (newOrder: UpdateStepDTO[]) => {
		const updatedSteps = newOrder.map((item, index) => ({
			...item,
			order: index + 1,
		}));

		await updateStepOrderMutation.mutateAsync({
			steps: updatedSteps,
			projectId: selectedProject?.id ?? "",
		});
	};

	const handleNavigation = (step: Step) => {
		setCurrentStep(step);
		return navigate("/projet/etapes/rules");
	};

	return (
		<div className="h-full">
			<div className="mb-8 flex items-center justify-between">
				<h2 className="text-primary">Etapes</h2>
				<Button
					className="rounded-lg bg-primary px-6 py-3"
					onClick={handleAddStep}
					disabled={addStepMutation.isLoading}
				>
					<span>Ajouter une étape</span>
					<CirclePlus className="ml-4" strokeWidth={1.25} absoluteStrokeWidth />
				</Button>
			</div>

			<div className="flex h-full flex-col items-start justify-between gap-6">
				<Sortable
					orientation="mixed"
					collisionDetection={pointerWithin}
					value={stepsData ?? []}
					onValueChange={handleReorder}
					overlay={<div className="size-full rounded-md bg-card/10" />}
				>
					<div className="grid w-full grid-cols-3 items-start gap-4">
						{stepsData?.map((step) => (
							<StepButton
								key={step.id}
								id={step.id}
								name={step.label ?? ""}
								onLeftIconClick={() => handleNavigation(step)}
								onRightIconClick={() => handleRemoveStep(step.id)}
							/>
						))}
					</div>
				</Sortable>
			</div>
		</div>
	);
};
