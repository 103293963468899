import { VariableType } from "@prisma/client";
import { v4 as uuidv4 } from "uuid";

import type { Operation } from "@repos/rate-resolver-dtos";

export const getFormattedValue = (type: VariableType, value: string) => {
	switch (type) {
		case VariableType.NUMBER: {
			return !isNaN(Number(value)) ? Number(value) : 0;
		}
		case VariableType.BOOLEAN: {
			return value.toLowerCase() === "true";
		}
		case VariableType.DATE: {
			const date = new Date(value);
			return isNaN(date.getTime()) ? new Date() : date;
		}
		case VariableType.STRING:
		case VariableType.ENUM:
		default: {
			return value;
		}
	}
};

export const getDefaultOperation = () => {
	return {
		id: uuidv4(),
		operands: [{ id: uuidv4() }, { id: uuidv4() }],
	} as Operation;
};
