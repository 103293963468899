import { Label } from "@/components/ui/label";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores/useAppStore";

import { getVariableToString } from "@repos/rate-resolver-shared";

function OuputVariables() {
	const { tarificationSummary } = useAppStore((state) => ({
		tarificationSummary: state.tarificationSummary,
	}));
	return (
		<div className="rounded-md border p-4">
			<Label className="text-lg font-bold text-black dark:text-white">
				Résultats tarification:
			</Label>
			{tarificationSummary && tarificationSummary.output.outputs.length > 0 && (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHead>Clefs</TableHead>
							<TableHead>Valeur</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{tarificationSummary.output.outputs.map((variable, index) => (
							<TableRow key={index}>
								<TableCell className="font-medium">{variable.key}</TableCell>
								<TableCell>{getVariableToString(variable.value)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			)}
		</div>
	);
}

export default OuputVariables;
