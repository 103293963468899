import type {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
} from "@tanstack/react-table";
import * as React from "react";
import { Button } from "@/components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";

import DataTableAccessory from "./DataTableAccessory";

export interface DataTableProps<TData, TValue, TActionComponentProps> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	dataTableAccessoryProps?: DataTableAccessoryProps<TActionComponentProps>;
}
export interface DataTableAccessoryProps<TActionComponentProps> {
	searchValue: string;
	onChangeSearchValue: (value: string) => void;
	buttonText: string;
	ActionComponent: React.ElementType;
	actionComponentProps: TActionComponentProps;
	buttonIcon: React.ReactNode;
}

export function DataTable<TData, TValue, T>({
	columns,
	data,
	dataTableAccessoryProps,
}: DataTableProps<TData, TValue, T>) {
	const [sorting, setSorting] = React.useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
		[],
	);

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			sorting,
			columnFilters,
		},
	});

	return (
		<div>
			{dataTableAccessoryProps && (
				<DataTableAccessory
					searchValue={dataTableAccessoryProps.searchValue}
					onChangeSearchValue={(value) =>
						dataTableAccessoryProps.onChangeSearchValue(value)
					}
					buttonText={dataTableAccessoryProps.buttonText}
					ActionComponent={dataTableAccessoryProps.ActionComponent}
					actionComponentProps={dataTableAccessoryProps.actionComponentProps}
					buttonIcon={dataTableAccessoryProps.buttonIcon}
				/>
			)}
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext(),
													)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<Button
					variant="outline"
					size="sm"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
				>
					Précedent
				</Button>
				<Button
					variant="outline"
					size="sm"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
				>
					Suivant
				</Button>
			</div>
		</div>
	);
}
