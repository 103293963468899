import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { FilePen, FlaskConical, Trash2 } from "lucide-react";

import { SortableDragHandle, SortableItem } from "./ui/sortable";

interface StepButtonProps {
	id: string;
	name: string;
	onLeftIconClick: () => void;
	onRightIconClick: () => void;
}

export const StepButton = ({
	id,
	onLeftIconClick,
	name,
	onRightIconClick,
}: StepButtonProps) => {
	return (
		<SortableItem value={id} key={id} asChild>
			<div className="flex justify-between gap-16 rounded-lg border border-slate-400 bg-white p-6 font-bold text-blue-500 shadow-lg dark:bg-zinc-900">
				{name}
				<div className="flex flex-row items-center justify-center gap-4">
					<FilePen onClick={onLeftIconClick} className="hover:cursor-pointer" />
					{id !== "output" ? (
						<Trash2
							color="red"
							onClick={onRightIconClick}
							className="hover:cursor-pointer"
						/>
					) : (
						<FlaskConical
							onClick={onRightIconClick}
							className="hover:cursor-pointer"
						/>
					)}
					<SortableDragHandle variant="default" size="icon" className="size-8">
						<DragHandleDots2Icon className="size-4" aria-hidden="true" />
					</SortableDragHandle>
				</div>
			</div>
		</SortableItem>
	);
};
