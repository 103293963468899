import type { SelectedColumn } from "@/components/enumImporter/types";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { X } from "lucide-react";

interface ColumnsFormProps {
	selectedLabelColumn: SelectedColumn | null;
	selectedValueColumn: SelectedColumn | null;
	shouldRemoveFirstRow: boolean;
	setShouldRemoveFirstRow: (shouldRemoveFirstRow: boolean) => void;
	onDeleteSelectedLabelColumn: () => void;
	onDeleteSelectedValueColumn: () => void;
	onSubmit: () => void;
}

export const ColumnsForm = ({
	selectedLabelColumn,
	selectedValueColumn,
	shouldRemoveFirstRow,
	setShouldRemoveFirstRow,
	onDeleteSelectedLabelColumn,
	onDeleteSelectedValueColumn,
	onSubmit,
}: ColumnsFormProps) => {
	return (
		<div className="mb-2 flex w-full flex-row items-center gap-4">
			<div className="flex flex-col gap-2">
				<Label>Colonne labels</Label>
				<div className="relative">
					<Input
						type="text"
						placeholder="Veuillez selectionner une colonne pour les labels"
						disabled
						className="pr-6"
						value={
							selectedLabelColumn?.label ||
							"Veuillez selectionner une colonne pour les labels"
						}
					/>
					{selectedLabelColumn !== null && (
						<Button
							variant={"ghost"}
							className="absolute right-0 top-0 rounded-full p-2 hover:bg-destructive-foreground"
							size={"icon"}
							type={"button"}
						>
							<X
								onClick={onDeleteSelectedLabelColumn}
								className="size-4 text-destructive"
							/>
						</Button>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-2">
				<Label>Colonne Valeurs</Label>
				<div className="relative">
					<Input
						type="text"
						placeholder="Veuillez selectionner une colonne pour les valeurs"
						disabled
						className="pr-6"
						value={
							selectedValueColumn?.label ||
							"Veuillez selectionner une colonne pour les valeurs"
						}
					/>
					{selectedValueColumn !== null && (
						<Button
							variant={"ghost"}
							className="absolute right-0 top-0 rounded-full p-2 hover:bg-destructive-foreground"
							size={"icon"}
							type={"button"}
						>
							<X
								onClick={onDeleteSelectedValueColumn}
								className="size-4 text-destructive"
							/>
						</Button>
					)}
				</div>
			</div>
			<div className="mt-4 flex items-center space-x-2">
				<Checkbox
					id="firstRowIsHeader"
					checked={shouldRemoveFirstRow}
					onCheckedChange={setShouldRemoveFirstRow}
					className="size-6"
				/>
				<label
					htmlFor="firstRowIsHeader"
					className="text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
				>
					Premiere ligne est en-tête
				</label>
			</div>
			<div className="flex flex-1 justify-end self-end">
				<Button
					disabled={
						selectedLabelColumn === null || selectedValueColumn === null
					}
					onClick={onSubmit}
				>
					Importer
				</Button>
			</div>
		</div>
	);
};
