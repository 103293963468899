import type { DataTableAccessoryProps } from "@/components/dataTable/data-table";
import { useState } from "react";
import { AddVariable } from "@/components/AddVariable";
import { getColumns } from "@/components/dataTable/columns";
import { DataTable } from "@/components/dataTable/data-table";
import { useDebounce } from "@/hooks/useDebounce";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { PlusCircle } from "lucide-react";

export const Variables = () => {
	const selectedProject = useAppStore((state) => state.selectedProject);
	const [search, setSearch] = useState<string>("");
	const debouncedSearch = useDebounce(search, 300);

	const utils = trpc.useUtils();
	const { data: variables } = trpc.variables.getVariables.useQuery({
		projectId: selectedProject?.id ?? "",
		search: debouncedSearch,
	});

	const headers: { [key: string]: string } = {
		label: "Label",
		category: "Categorie",
		defaultValue: "Valeur Par défaut",
		type: "Type",
	};

	const accessoryDataTableProps = {
		searchValue: search,
		onChangeSearchValue: setSearch,
		buttonText: "Ajouter une variable",
		ActionComponent: AddVariable,
		actionComponentProps: {
			onVariableCreated: () => {
				utils.variables.getVariables.invalidate({
					projectId: selectedProject?.id ?? "",
				});
			},
		},
		buttonIcon: <PlusCircle className="mr-2 h-4 w-4" />,
	} satisfies DataTableAccessoryProps<{ onVariableCreated: () => void }>;

	return (
		<div className="overflow-x-auto">
			<DataTable
				columns={getColumns({
					headers,
					refetchVariables: () =>
						utils.variables.getVariables.invalidate({
							projectId: selectedProject?.id ?? "",
						}),
				})}
				data={variables ?? []}
				dataTableAccessoryProps={accessoryDataTableProps}
			/>
		</div>
	);
};
