import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";
import type { AnyOperationOperators } from "@repos/rate-resolver-shared";
import { getAllowedOperators } from "@repos/rate-resolver-shared";

const useOperationOptions = (
	assignedVariableType?: VariableCreateDTO["data"]["type"],
	firstOperandType?: VariableCreateDTO["data"]["type"],
): {
	disabled: boolean;
	allowedOperations: { label: string; key: AnyOperationOperators }[];
} => {
	let disabled = false;
	let allowedOperations: ReturnType<typeof getAllowedOperators> = [];
	console.log({ assignedVariableType, firstOperandType });
	if (assignedVariableType && firstOperandType) {
		allowedOperations = getAllowedOperators({
			assignedVariableType,
			firstOperandType,
		});
		return { disabled: false, allowedOperations };
	} else {
		disabled = true;
		allowedOperations = [];
	}

	return { disabled, allowedOperations };
};

export default useOperationOptions;
