import type { ColumnIndicatorComponent } from "react-spreadsheet";
import React, { useCallback } from "react";
import { cn } from "@/utils";

export const CustomExcelColumn: ColumnIndicatorComponent = ({
	column,
	label,
	selected,
	onSelect,
}) => {
	const handleClick = useCallback(
		(event: React.MouseEvent) => {
			onSelect(column, event.shiftKey);
		},
		[onSelect, column],
	);
	return (
		<th
			className={cn("Spreadsheet__header", {
				"Spreadsheet__header--selected": selected,
			})}
			onClick={handleClick}
			tabIndex={0}
		>
			{label !== undefined ? label : columnIndexToLabel(column)}
		</th>
	);
};

export function columnIndexToLabel(column: number): string {
	let label = "";
	let index = column;
	while (index >= 0) {
		label = String.fromCharCode(65 + (index % 26)) + label;
		index = Math.floor(index / 26) - 1;
	}
	return label;
}
