import { useState } from "react";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil } from "lucide-react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "sonner";

import type { TarificationStatusUpdateDto } from "@repos/rate-resolver-dtos";
import type { ProjectType } from "@repos/rate-resolver-shared";
import { pricingEngineStatusUpdateSchema } from "@repos/rate-resolver-dtos";

import { UpdateProjectForm } from "../projects/UpdateProjectForm";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";

interface ProjectCardProps {
	project: ProjectType;
	onSelect: () => void;
}

const ProjectCard = ({
	project: { id: projectId, name: title, description, image, status },
	onSelect,
}: ProjectCardProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const utils = trpc.useUtils();
	const { control, setValue } = useForm<TarificationStatusUpdateDto>({
		resolver: zodResolver(pricingEngineStatusUpdateSchema),
		defaultValues: {
			projectId: projectId,
			status,
		},
	});

	const statusValue = useWatch({ name: "status", control });

	const updatePricingEngineStatus = trpc.pricingEngine.updateStatus.useMutation(
		{
			onSuccess: () => {
				toast.success("Le statut du tarificateur a été changé avec succès.", {
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				});
				utils.projects.getProjects.invalidate();
			},
			onError: (error) => {
				toast.error(error.message, {
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				});
			},
		},
	);

	const handleStatusChange = async (newStatus: boolean) => {
		const newStatusValue = newStatus ? "PUBLISHED" : "DRAFT";
		try {
			await updatePricingEngineStatus.mutateAsync({
				projectId: projectId,
				status: newStatusValue,
			});
		} catch (error) {
			console.error(error);
			setValue("status", status);
		}
	};

	return (
		<>
			<Card className="group relative cursor-pointer overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out hover:-translate-y-2 hover:shadow-xl">
				<CardHeader>
					<img
						src={image || "https://via.placeholder.com/300"}
						alt={title}
						width={300}
						height={300}
						className="mx-auto h-64 w-48 object-contain"
						onClick={onSelect}
					/>
				</CardHeader>
				<CardContent className="h-32 bg-background pl-4 pt-4">
					<div onClick={onSelect}>
						<h3 className="text-lg font-semibold">{title}</h3>
						<p
							onMouseEnter={() => setIsExpanded(true)}
							onMouseLeave={() => setIsExpanded(false)}
							className={cn(
								"text-sm text-muted-foreground",
								isExpanded ? "" : "line-clamp-2",
							)}
						>
							{description}
						</p>
					</div>
				</CardContent>
				<CardFooter className="mb-4 flex w-full items-center justify-between bg-background">
					<div className="flex items-center gap-2">
						<Controller
							name="status"
							control={control}
							render={({ field }) => (
								<Switch
									id={`published-${projectId}`}
									checked={field.value === "PUBLISHED"}
									onCheckedChange={(checked) => {
										const newStatusValue = checked ? "PUBLISHED" : "DRAFT";
										field.onChange(newStatusValue);
										handleStatusChange(checked);
									}}
									aria-label={`Toggle pricing engine status: currently ${status}`}
								/>
							)}
						/>
						<Label htmlFor={`published-${projectId}`} className="text-sm">
							{statusValue === "PUBLISHED" ? "Publié" : "Test"}
						</Label>
					</div>
					<Button
						type="button"
						variant={"ghost"}
						onClick={() => setIsOpen(true)}
					>
						<span className="sr-only">Edit project</span>
						<Pencil className="size-6" />
					</Button>
				</CardFooter>
			</Card>
			<Dialog open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent className="z-50 sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>Ajouter un projet</DialogTitle>
					</DialogHeader>
					<UpdateProjectForm
						projectId={projectId}
						onProjectUpdated={() => setIsOpen(false)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

const ProjectCardLoading = () => {
	return (
		<div className="flex h-full w-full flex-col space-y-3 overflow-hidden rounded-xl border bg-card">
			<Skeleton className="h-[250px] bg-card" />
			<div className="space-y-2 bg-background p-4">
				<Skeleton className="h-5 w-[250px]" />
				<Skeleton className="h-5 w-[200px]" />
			</div>
		</div>
	);
};

export { ProjectCard, ProjectCardLoading };
