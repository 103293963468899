import React from "react";

interface CustomErrorTooltipProps {
	message: string;
	className?: string;
}

const CustomErrorTooltip: React.FC<CustomErrorTooltipProps> = ({
	message,
	className,
}) => {
	return (
		<div className={`relative flex justify-center ${className}`}>
			<div className="absolute -top-14 w-full max-w-xs rounded bg-red-500 px-2 py-1 text-white shadow-md">
				<div className="flex items-center justify-between">
					<span className="px-2 py-1 text-xs">{message}</span>
				</div>
				<div className="border-l-6 border-r-6 border-t-6 absolute bottom-[-6px] right-5 z-0 size-3 -translate-x-1/2 rotate-45 transform border-l-transparent border-r-transparent border-t-red-500 bg-red-500" />
			</div>
		</div>
	);
};

export default CustomErrorTooltip;
