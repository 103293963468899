import { z } from "zod";

export const createBlankStepSchema = z.object({
	projectId: z.string().uuid(),
});

export const getStepsSchema = z.object({
	projectId: z.string().uuid(),
});

export const updateStepSchema = z.object({
	id: z.string().uuid(),
	label: z
		.string()
		.min(3, "Veuillez entrer au moins trois caractères")
		.optional(),
	order: z
		.number()
		.int("L'ordre doit être un entier")
		.min(1, "L'ordre de l'étape doit être supérieur à 0"),
});

export type UpdateStepDTO = z.infer<typeof updateStepSchema>;

export const deleteStepSchema = z.object({
	id: z.string().uuid(),
});

export type DeleteStepDTO = z.infer<typeof deleteStepSchema>;

export const updateStepsOrderSchema = z.object({
	steps: z.array(updateStepSchema),
	projectId: z.string().uuid(),
});

export type UpdateStepsOrderDTO = z.infer<typeof updateStepsOrderSchema>;
