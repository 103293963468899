import { VariableCategory, VariableType } from "@prisma/client";
import { z } from "zod";

import {
	enumValueSchema,
	isVariableValueValid,
	keySchema,
	valueSchema,
} from "../primitives/variablePrimitives";

export const VariableCreateSchema = z
	.object({
		projectId: z.string().uuid().nullable(),
		data: z.object({
			key: keySchema,
			label: z.string().min(3, "Veuillez entrer au moins trois caractères"),
			type: z.nativeEnum(VariableType),
			value: valueSchema,
			category: z.nativeEnum(VariableCategory),
			enumValues: z.array(enumValueSchema).optional(),
		}),
	})
	.superRefine(
		({ data: { type, value, category, enumValues }, projectId }, ctx) => {
			// validate enum values & value if enum
			if (type === "ENUM") {
				if (!enumValues) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Veuillez fournir des valeurs d'énumération`,
						path: ["data", "enumValues"],
					});
				} else {
					if (enumValues.length < 2) {
						ctx.addIssue({
							code: z.ZodIssueCode.too_small,
							inclusive: true,
							minimum: 2,
							type: "array",
							message: `Veuillez entrer au moins deux options`,
							path: ["data", "enumValues"],
						});
					}
					if (!enumValues.some((enumValue) => enumValue.value === value)) {
						ctx.addIssue({
							code: z.ZodIssueCode.custom,
							message: `Veuillez choisir une option valide`,
							path: ["data", "value"],
						});
					}
				}
			}

			// validate value by type
			const isValueValid = isVariableValueValid({
				value,
				type,
				acceptNullOrUndefined: category === "INPUT",
			});
			if (!isValueValid) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Le type de la valeur est invalide`,
					path: ["data", "value"],
				});
			}

			const isProjectIdValid = z.string().uuid().safeParse(projectId).success;
			if (!isProjectIdValid && category !== "GLOBAL_CONFIG") {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Veuillez fournir un id de projet valide`,
					path: ["projectId"],
				});
			}
		},
	);

export type VariableCreateDTO = z.infer<typeof VariableCreateSchema>;

export const VariableUpdateSchema = z
	.object({
		id: z.string().uuid(),
		type: z.nativeEnum(VariableType),
		category: z.nativeEnum(VariableCategory),
		data: z.object({
			key: z.string().min(3, "Veuillez entrer au moins trois caractères"),
			label: z.string().min(3, "Veuillez entrer au moins trois caractères"),
			value: valueSchema,
			enumValues: z
				.array(enumValueSchema)
				.min(2, "Veuillez entrer au moins deux options")
				.optional(),
		}),
	})
	.superRefine(({ data: { value, enumValues }, type, category }, ctx) => {
		// validate enum values & value if enum
		if (type === "ENUM") {
			if (!enumValues) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Veuillez fournir des valeurs d'énumération`,
					path: ["data", "enumValues"],
				});
			} else {
				if (!enumValues.some((enumValue) => enumValue.value === value)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Veuillez choisir une option valide`,
						path: ["data", "value"],
					});
				}
			}
		}

		const isValid = isVariableValueValid({
			value,
			type,
			acceptNullOrUndefined: category === "INPUT",
		});

		if (!isValid) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Le type de la valeur est invalide`,
				path: ["data", "value"],
			});
		}
	});

export type VariableUpdateDTO = z.infer<typeof VariableUpdateSchema>;

export const VariableSearchSchema = z
	.object({
		projectId: z.string().uuid(),
		search: z.string().optional(),
		category: z.nativeEnum(VariableCategory).optional(),
		types: z.array(z.nativeEnum(VariableType)).optional(),
	})
	.transform(({ projectId, search, category, types }) => {
		// if search is whitespace, remove it from the params
		if (search?.trim().length === 0) {
			return { projectId, category, types };
		}
		return { projectId, search, category, types };
	});

export type VariableSearchDTO = z.infer<typeof VariableSearchSchema>;

export const testTarificationSchema = z
	.object({
		inputs: z.array(
			z.object({
				value: valueSchema,
				type: z.nativeEnum(VariableType),
				key: keySchema,
			}),
		),
	})
	.superRefine(({ inputs }, ctx) => {
		inputs.forEach(({ value, type }, index) => {
			const isValid = isVariableValueValid({
				value,
				type,
				acceptNullOrUndefined: true,
			});
			if (!isValid) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "",
					path: ["inputs", index, "value"],
				});
			}
		});
	});

export type TestTarificationDTO = z.infer<typeof testTarificationSchema>;
