import type { ApiOutputs } from "@repos/rate-resolver-api";
import type { ConditionType } from "@repos/rate-resolver-shared";

export type Condition = {
	field?: string;
	operator?: string;
	value?: string;
};

export type ConditionGroupType = {
	operator: ConditionType;
	conditions: Condition[];
};

export type Step = ApiOutputs["steps"]["getSteps"][0];
export type SubStepsResponse = ApiOutputs["subSteps"]["getSubSteps"];
export type SubStepResponse = ApiOutputs["subSteps"]["getSubSteps"][0];

export const TabsLabels = {
	VARIABLES: "Variables",
	ETAPES: "Etapes",
	OUTPUT: "Output",
	TARIFICATION: "Tarification",
} as const;

export type TabsLabelsType = (typeof TabsLabels)[keyof typeof TabsLabels];

export type Summary = ApiOutputs["pricingEngine"]["testRate"];
const OperationEnumType = {
	Operations: "operations",
	FallbackOperations: "fallbackOperations",
} as const;

export type OperationEnumType =
	(typeof OperationEnumType)[keyof typeof OperationEnumType];