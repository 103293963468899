import { OutputStepForm } from "@/components/outputStep/OutputStepForm";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";

export const OutputStep = () => {
	const selectedProject = useAppStore((state) => state.selectedProject);
	const { data: defaultFinalStep, isLoading } =
		trpc.steps.getFinalStep.useQuery({
			projectId: selectedProject?.id ?? "",
		});

	return (
		<div className="flex h-full w-full flex-col items-center">
			<div className="mb-8 mt-4  flex items-center justify-between">
				<h2 className="text-primary dark:text-white">
					Veuillez définir les résultats de tarification
				</h2>
			</div>
			<div className="flex ">
				{!isLoading && <OutputStepForm defaultFinalStep={defaultFinalStep} />}
			</div>
		</div>
	);
};
