import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";
import type { ConditionOperator } from "@repos/rate-resolver-shared";
import {
	conditionOperatorToSymbol,
	getConditionOperators,
} from "@repos/rate-resolver-shared";

export interface OperationOption {
	label: string;
	value: ConditionOperator;
}

const useConditionOperationOptions = (
	type?: VariableCreateDTO["data"]["type"],
) => {
	let disabled = false;
	let allowedOperations: ReturnType<typeof getConditionOperators> = [];
	let formattedOperations: OperationOption[] = [];

	if (type) {
		allowedOperations = getConditionOperators(type);
		formattedOperations = allowedOperations.map((operation) => ({
			label: conditionOperatorToSymbol(operation, type),
			value: operation,
		}));
	} else {
		disabled = true;
		formattedOperations = [];
	}

	return { disabled, formattedOperations };
};

export default useConditionOperationOptions;
