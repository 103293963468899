import { useMemo, useState } from "react";
import { AddVariable } from "@/components/AddVariable";
import { VariableIcon } from "@/components/subStep/variableSelect/VariableIcon";
import { VariablesList } from "@/components/subStep/variableSelect/VariablesList";
import { Button } from "@/components/ui/button";
import {
	Command,
	CommandInput,
	CommandList,
} from "@/components/ui/command.tsx";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { Plus, X } from "lucide-react";

import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import {
	getVariableToString,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

export interface VariableSelectProps {
	selectedItem: SimpleVariable | null | undefined;
	variableTypes?: VariableCreateDTO["data"]["type"][];
	onSelect: (variable: SimpleVariable) => void;
	disabled?: boolean;
	allowRawValue?: boolean;
	rawValue?: string | number | boolean | Date | null | undefined;
	onRawValueSubmit?: (
		value: string | number | boolean | Date | null | undefined,
	) => void;
	onClear?: () => void;
}
// TODO: Handle rhf error messages
export const VariableSelect = ({
	selectedItem,
	variableTypes,
	onSelect,
	disabled = false,
	allowRawValue = false,
	onRawValueSubmit,
	rawValue,
	onClear,
}: VariableSelectProps) => {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState<string>("");
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));

	const { data: variables = [], refetch } =
		trpc.variables.getVariables.useQuery({
			projectId: selectedProject?.id ?? "",
			types: variableTypes,
			search,
		});

	const label = useMemo(() => {
		if (selectedItem) {
			// Search for the updated label of the selectedItem in the current list of variables.
			const correspondingVariable = variables.find(
				(variable) => variable.key === selectedItem.key,
			);
			return correspondingVariable
				? correspondingVariable.label
				: "Choisir une variable";
		} else if (allowRawValue && !isNullOrUndefined(rawValue)) {
			return getVariableToString(rawValue);
		}
		return "Choisir une variable";
	}, [allowRawValue, rawValue, selectedItem, variables]);

	const hasLabel = useMemo(() => {
		return label !== "Choisir une variable";
	}, [label]);

	return (
		<Popover open={open} onOpenChange={disabled ? undefined : setOpen}>
			<PopoverTrigger asChild>
				<Button
					className={
						"group relative flex h-auto w-52 items-center justify-start border border-input bg-transparent px-3 py-2 text-sm text-black shadow-sm ring-offset-background placeholder:text-muted-foreground hover:bg-sky-500 focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed dark:text-white"
					}
					disabled={disabled}
				>
					{selectedItem && hasLabel && (
						<VariableIcon type={selectedItem.type} className="mr-2 size-3" />
					)}
					<span className="line-clamp-1 w-48">{label}</span>
					{onClear && (
						<span
							onClick={(e) => {
								e.stopPropagation();
								onClear();
							}}
							className="absolute right-1 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-white p-1 group-hover:bg-red-600 dark:bg-transparent"
						>
							<X className="size-3 text-red-600 group-hover:text-white dark:text-white " />
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent
				className="min-w-[200px] p-0"
				tabIndex={0}
				onWheel={(e) => e.stopPropagation()}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="max-h-[300px] overflow-y-auto">
					<Command>
						<div className="relative border-b border-slate-300">
							<CommandInput value={search} onValueChange={setSearch} />

							<div className="absolute right-2 top-3 ">
								<Popover modal={true}>
									<PopoverTrigger asChild>
										<Button
											className="size-4 place-self-end"
											variant="outline"
											size="icon"
										>
											<Plus className="h-4 w-4" />
										</Button>
									</PopoverTrigger>
									<AddVariable
										onVariableCreated={(variable) => {
											onSelect(variable);
											setOpen(false);
											// refetch to get the new variable
											refetch();
										}}
									/>
								</Popover>
							</div>
						</div>
						<CommandList>
							<VariablesList
								variables={variables}
								selectedItem={selectedItem?.key}
								selectedItemType={variableTypes}
								onItemSelect={(variable) => {
									onSelect(variable);
									setOpen(false);
								}}
								rawValue={rawValue}
								allowRawValue={allowRawValue}
								onRawValueSubmit={(value) => {
									if (allowRawValue && onRawValueSubmit) {
										onRawValueSubmit(value);
										setOpen(false);
									}
								}}
							/>
						</CommandList>
					</Command>
				</div>
			</PopoverContent>
		</Popover>
	);
};
