import AdminPanelLayout from "@/components/admin-panel/admin-panel-layout";
import ModeToggle from "@/components/admin-panel/mode-toggle";
import { SheetMenu } from "@/components/admin-panel/sheet-menu";
import { UserNav } from "@/components/admin-panel/user-nav";
import { useAppStore } from "@/stores/useAppStore";
import { Outlet } from "react-router-dom";

export const Layout = () => {
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));

	return (
		<AdminPanelLayout>
			<div className="relative flex h-auto flex-col">
				<header className="sticky top-0 z-10 w-full bg-background/95 shadow backdrop-blur supports-[backdrop-filter]:bg-background/60">
					<div className="mx-4 flex h-14 items-center sm:mx-8">
						<div className="flex items-center space-x-4 lg:space-x-0">
							<SheetMenu />
							<h1 className="font-bold">{selectedProject?.name}</h1>
						</div>
						<div className="flex flex-1 items-center justify-end gap-2">
							<ModeToggle />
							<UserNav />
						</div>
					</div>
				</header>
				<main className="m-6 flex h-full flex-col rounded-lg bg-white p-6 shadow-lg dark:bg-zinc-800">
					<Outlet />
				</main>
			</div>
		</AdminPanelLayout>
	);
};
