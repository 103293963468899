import type { Operation } from "@repos/rate-resolver-dtos";

export type PricingEngineInput = {
	[key: string]: any;
};

export enum OperandType {
	FIELD = "FIELD",
	VALUE = "VALUE",
}

export type OperandValue = string | number | boolean | Date;

export type FinalStep = {
	id: string;
	operations: Operation[];
	pricingEngineId: string;
};
