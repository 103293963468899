import { AppProvider } from "@/AppProvider";
import { Toaster } from "@/components/ui/sonner";
import { AppRoutes } from "@/routes";

function App() {
	return (
		<AppProvider>
			<AppRoutes />
			<Toaster />
		</AppProvider>
	);
}

export default App;
