import { z } from "zod";

import { operationSchema } from "../primitives";

export const updateFinalStepSchema = z.object({
	projectId: z.string().uuid(),
	operations: z.array(operationSchema),
});

export type UpdateFinalStepDTO = z.infer<typeof updateFinalStepSchema>;
