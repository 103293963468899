import { VariableType } from "@prisma/client";

import type { ConditionOperator } from "../../config";
import {
	BooleanConditionOperator,
	DateConditionOperator,
	NumberConditionOperator,
	StringOrEnumConditionOperator,
} from "../../config";

export const getConditionOperators = <T extends VariableType>(
	type: T,
): ConditionOperator<T>[] => {
	switch (type) {
		case VariableType.STRING:
		case VariableType.ENUM:
			return Object.values(
				StringOrEnumConditionOperator,
			) as ConditionOperator<T>[];
		case VariableType.BOOLEAN:
			return Object.values(BooleanConditionOperator) as ConditionOperator<T>[];
		case VariableType.NUMBER:
			return Object.values(NumberConditionOperator) as ConditionOperator<T>[];
		case VariableType.DATE:
			return Object.values(DateConditionOperator) as ConditionOperator<T>[];
		default:
			return [];
	}
};

export const conditionOperatorToSymbol = (
	type: ConditionOperator,
	variableType: VariableType,
) => {
	if (variableType === VariableType.NUMBER) {
		switch (type) {
			case NumberConditionOperator.EQUALS:
				return "=";
			case NumberConditionOperator.GREATER_THAN:
				return ">";
			case NumberConditionOperator.LESS_THAN:
				return "<";
			default:
				return "";
		}
	}
	if (variableType === VariableType.DATE) {
		switch (type) {
			case DateConditionOperator.IS_AFTER:
				return "Est après";
			case DateConditionOperator.IS_BEFORE:
				return "Est avant";
			case DateConditionOperator.IS_EQUAL:
				return "Est égal";
			case DateConditionOperator.IS_EQUAL_OR_AFTER:
				return "Est après ou égal";
			case DateConditionOperator.IS_EQUAL_OR_BEFORE:
				return "Est avant ou égal";
			default:
				return "";
		}
	}
	if (variableType === VariableType.BOOLEAN) {
		switch (type) {
			case BooleanConditionOperator.EQUALS:
				return "=";
			default:
				return "";
		}
	}
	if (
		variableType === VariableType.STRING ||
		variableType === VariableType.ENUM
	) {
		switch (type) {
			case StringOrEnumConditionOperator.EQUALS:
				return "=";
			default:
				return "";
		}
	}
	return "";
};
