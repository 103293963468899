import { Label } from "@/components/ui/label";

import type { ApiOutputs } from "@repos/rate-resolver-api";
import { getVariableToString } from "@repos/rate-resolver-shared";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../ui/table";

interface StepSummaryCardProps {
	stepSummary: ApiOutputs["pricingEngine"]["testRate"]["summary"]["stepSummaries"][0];
	stepNumber: number;
}
function StepSummaryCard({ stepSummary, stepNumber }: StepSummaryCardProps) {
	return (
		<div className="my-3 flex flex-col justify-center rounded-md  border border-b-2 border-blue-500 p-4">
			<Label className="text-lg font-bold text-black dark:text-white">
				{" "}
				Etape {stepNumber} : {stepSummary.stepLabel}{" "}
			</Label>
			<div>
				<Label>Sous étapes</Label>
				{stepSummary.subStepSummaries.map((subStep, index) => (
					<ul key={index} className="ml-7 list-disc">
						<li>Type condition : {subStep.conditionType} </li>
						<li>Résultat condition : {subStep.conditionsResult}</li>
					</ul>
				))}
			</div>
			<div className="flex flex-col justify-center">
				<Label>Détails conditions</Label>
				<div className="my-3 rounded-md border">
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>Condition</TableHead>
								<TableHead>Valeur</TableHead>
								<TableHead>Résultat</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{stepSummary.subStepSummaries.map((conditionSummary) =>
								conditionSummary.conditionSummaries.map((condition, index) => (
									<TableRow key={index}>
										<TableCell className="font-medium">
											{condition.condition}
										</TableCell>
										<TableCell>{condition.values}</TableCell>
										<TableCell className="text-right">
											{getVariableToString(condition.result)}
										</TableCell>
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
				</div>
			</div>
			<div className="flex flex-col justify-center">
				<Label>Détails opérations</Label>
				<div className="my-3 rounded-md border">
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>operation</TableHead>
								<TableHead>Valeur</TableHead>
								<TableHead>Résultat</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{stepSummary.subStepSummaries.map((conditionSummary) =>
								conditionSummary.operationSummaries.map((operation, index) => (
									<TableRow key={index}>
										<TableCell className="font-medium">
											{operation.operation}
										</TableCell>
										<TableCell>{operation.values}</TableCell>
										<TableCell className="text-right">
											{getVariableToString(operation.result)}
										</TableCell>
									</TableRow>
								)),
							)}
						</TableBody>
					</Table>
				</div>
			</div>
		</div>
	);
}

export default StepSummaryCard;
