import type { SelectedColumn } from "@/components/enumImporter/types";
import type { ColumnIndicatorProps } from "react-spreadsheet";
import { useCallback, useEffect, useState } from "react";
import { ColumnsForm } from "@/components/enumImporter/ColumnsForm";
import { columnIndexToLabel } from "@/components/enumImporter/customSpreadsheetComponents/CustomExcelColumn";
import { SelectionMode } from "@/components/enumImporter/types";
import * as XLSX from "xlsx";

import type { EnumValue } from "@repos/rate-resolver-dtos";

import { CustomSpreadSheet } from "./customSpreadsheetComponents/CustomSpreadSheet";

interface SpreadSheetFormProps {
	workbook: XLSX.WorkBook | null;
	selectedSheet: string | null;
	onImport: (options: EnumValue[]) => void;
}

export const SpreadSheetForm = ({
	workbook,
	selectedSheet,
	onImport,
}: SpreadSheetFormProps) => {
	const [sheetData, setSheetData] = useState<
		({ value: string } | undefined)[][]
	>([]);
	const [selectionMode, setSelectionMode] = useState<SelectionMode>(
		SelectionMode.LABEL,
	);
	const [selectedLabelColumn, setSelectedLabelColumn] =
		useState<SelectedColumn | null>(null);
	const [selectedValueColumn, setSelectedValueColumn] =
		useState<SelectedColumn | null>(null);

	const [shouldRemoveFirstRow, setShouldRemoveFirstRow] = useState(false);

	useEffect(() => {
		if (workbook && selectedSheet) {
			// Parse the selected sheet data
			const worksheet = workbook.Sheets[selectedSheet];
			if (!worksheet) {
				console.error("Worksheet not found");
				return;
			}

			// TODO: validate json data
			const json = XLSX.utils.sheet_to_json(worksheet, {
				header: 1,
				blankrows: false,
				defval: "",
			});
			if (Array.isArray(json)) {
				const formattedData = json.map((row) => {
					if (Array.isArray(row)) {
						return row.map((cell) => ({ value: cell || "" }));
					}
					return [];
				});
				setSheetData(formattedData);
				setSelectedLabelColumn(null);
				setSelectedValueColumn(null);
				setSelectionMode(SelectionMode.LABEL);
			}
		}
	}, [workbook, selectedSheet]);

	const onColumnSelect = useCallback(
		(column: number, extend: boolean, props: ColumnIndicatorProps) => {
			props.onSelect(column, extend);
			if (selectionMode === SelectionMode.LABEL) {
				const columnData = sheetData.map((row) => row[column]?.value || "");
				setSelectedLabelColumn({
					label: columnIndexToLabel(column),
					data: columnData,
				});
				setSelectionMode(SelectionMode.VALUE); // Switch to selecting the value column next
			} else {
				const columnData = sheetData.map((row) => row[column]?.value || "");
				setSelectedValueColumn({
					label: columnIndexToLabel(column),
					data: columnData,
				});
			}
		},
		[selectionMode, sheetData],
	);

	const onDeleteSelectedLabelColumn = useCallback(() => {
		setSelectedLabelColumn(null);
		setSelectionMode(SelectionMode.LABEL);
	}, [setSelectedLabelColumn, setSelectionMode]);

	const onDeleteSelectedValueColumn = useCallback(() => {
		setSelectedValueColumn(null);
	}, [setSelectedValueColumn]);

	const onSubmit = useCallback(() => {
		if (!selectedLabelColumn || !selectedValueColumn) {
			console.error("Both label and value columns must be selected.");
			return;
		}

		const labels = selectedLabelColumn.data;
		const values = selectedValueColumn.data;

		let enumValues = labels
			.map((label, index) => ({
				label: label,
				value: values[index],
			}))
			.filter((item) => item.value !== undefined && item.value.trim() !== "");

		if (shouldRemoveFirstRow) {
			enumValues = enumValues.slice(1);
		}

		const uniqueEnumValues = Array.from(
			// Filters out duplicate values
			new Map(enumValues.map((item) => [item.value, item])).values(),
		);

		onImport(uniqueEnumValues as EnumValue[]);
	}, [
		selectedLabelColumn,
		selectedValueColumn,
		shouldRemoveFirstRow,
		onImport,
	]);

	return (
		<>
			<ColumnsForm
				shouldRemoveFirstRow={shouldRemoveFirstRow}
				selectedLabelColumn={selectedLabelColumn}
				selectedValueColumn={selectedValueColumn}
				setShouldRemoveFirstRow={setShouldRemoveFirstRow}
				onDeleteSelectedLabelColumn={onDeleteSelectedLabelColumn}
				onDeleteSelectedValueColumn={onDeleteSelectedValueColumn}
				onSubmit={onSubmit}
			/>
			<div className="mt-4 h-[53vh] overflow-auto">
				{sheetData.length > 0 ? (
					<CustomSpreadSheet
						sheetData={sheetData}
						onColumnSelect={onColumnSelect}
					/>
				) : (
					<div className="flex h-full items-center justify-center">
						<p className="text-gray-500">Veuillez séléctionner un classeur</p>
					</div>
				)}
			</div>
		</>
	);
};
