import { Layout } from "@/layouts/Layout";
import { OutputStep } from "@/pages/OutputStep";
import Projects from "@/pages/projects";
import Rules from "@/pages/rules";
import { Steps } from "@/pages/Steps";
import { Tarification } from "@/pages/Tarification";
import { Variables } from "@/pages/Variables";
import { useAppStore } from "@/stores/useAppStore";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const { currentStep } = useAppStore((state) => ({
		currentStep: state.currentStep,
	}));

	const commonRoutes = [
		{
			path: "/",
			element: <Navigate to="/projets" replace />,
		},
		{
			element: <Layout />,
			children: [
				{
					path: "/projets",
					element: <Projects />,
				},
				{
					path: "/projet/etapes",
					element: <Steps />,
				},
				{
					path: "/projet/etapes/rules",
					element: currentStep && <Rules step={currentStep} />,
				},
				{
					path: "/projet/variables",
					element: <Variables />,
				},
				{
					path: "/projet/output",
					element: <OutputStep />,
				},
				{
					path: "/projet/tarification",
					element: <Tarification />,
				},
			],
		},
	];

	const routes = useSentryRoutes([...commonRoutes]);
	return routes;
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};
