import type { ColumnIndicatorProps } from "react-spreadsheet";
import React from "react";
import { CustomExcelColumn } from "@/components/enumImporter";
import Spreadsheet from "react-spreadsheet";

type SpreadsheetProps = {
	sheetData: ({ value: string } | undefined)[][];
	onColumnSelect: (
		column: number,
		extend: boolean,
		props: ColumnIndicatorProps,
	) => void;
};
const CustomSpreadsheetDef = ({
	sheetData,
	onColumnSelect,
}: SpreadsheetProps) => {
	return (
		<Spreadsheet
			data={sheetData} // !! This causes too many re renders
			// onChange={setSheetData}
			ColumnIndicator={(props) => (
				<CustomExcelColumn
					{...props}
					onSelect={(col, extend) => onColumnSelect(col, extend, props)}
				/>
			)}
		/>
	);
};

export const CustomSpreadSheet = React.memo(CustomSpreadsheetDef);
