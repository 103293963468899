import { VariableType } from "@prisma/client";
import { z } from "zod";

import type { OperatorConfig } from "@repos/rate-resolver-shared";
import {
	AllOperationOperators,
	getAllowedOperators,
	getOperationsConfig,
	isNullOrUndefined,
	OperandType,
} from "@repos/rate-resolver-shared";

import { isVariableValueValid } from "./variablePrimitives";

export const operandSchema = z
	.object({
		id: z.string().uuid(),
		type: z.nativeEnum(OperandType),
		variableType: z.nativeEnum(VariableType),
		value: z.union([z.string(), z.number(), z.boolean(), z.date()]),
	})
	.superRefine((data, ctx) => {
		const { type, value } = data;
		// Check that value is provided for field operands
		if (type === OperandType.FIELD) {
			const valid = z.string().safeParse(value).success;
			if (!valid) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: "Veuillez renseigner une valeur valide",
					path: ["value"],
				});
			}
		}
	});

export type Operand = z.infer<typeof operandSchema>;

export const operationSchema = z
	.object({
		id: z.string().uuid(),
		operationType: z.nativeEnum(AllOperationOperators).optional(),
		assignedVariable: z.string(),
		assignedVariableType: z.nativeEnum(VariableType),
		operands: z.array(operandSchema),
	})
	.superRefine((data, ctx) => {
		const { operationType, operands, assignedVariableType } = data;
		// validate some operations
		if (operationType === AllOperationOperators.DIVISION) {
			const operandValues = operands.map((operand) => operand.value);
			if (operandValues.some((value) => value === 0)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `La division par zéro n'est pas autorisée`,
					path: ["operationType"],
				});
			}
		}
		if (operationType === AllOperationOperators.MULTIPLICATION) {
			const operandValues = operands.map((operand) => operand.value);
			if (operandValues.some((value) => value === 0)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `La multiplication par zéro n'est pas autorisée`,
					path: ["operationType"],
				});
			}
		}

		if (operands.length === 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Veuillez renseigner au moins une variable ou une valeur`,
				path: ["operands"],
			});
		}
		if (operands.length > 1 && operationType === undefined) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Veuillez renseigner un type d'opération`,
				path: ["operationType"],
			});
		}
		// validate that boolean operations have only two operands
		if (operands.length > 2 && assignedVariableType === VariableType.BOOLEAN) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `Les operations de type vrai/faux ne peuvent accepter plus que deux variables`,
				//TODO: check if this is the correct path
				path: ["operands", operands.length - 1, "value"],
			});
		}

		if (operands.length > 1 && operationType) {
			const [firstOperand, ...otherOperands] = operands;
			const firstOperandType = firstOperand!.variableType;
			const allowedOperators = getAllowedOperators({
				assignedVariableType,
				firstOperandType,
			});

			// validate operation type
			if (
				!allowedOperators
					.map((operator) => operator.key)
					.includes(operationType)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `L'opération ${operationType} n'est pas autorisée pour les types de variables ${firstOperandType}`,
					path: ["operationType"],
				});
			}
			// validate first operand type
			const operationConfig = getOperationsConfig(assignedVariableType);
			const operatorConfig = operationConfig[operationType] as OperatorConfig;
			if (
				!operatorConfig.firstOperandVariableTypes.includes(firstOperandType)
			) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Le type de variable ${firstOperandType} n'est pas autorisée pour l'opérateur ${operatorConfig.label[assignedVariableType] ?? operatorConfig.key}`,
					path: ["operands", 0, "variableType"],
				});
			}

			// validate other operand types
			otherOperands.forEach((operand) => {
				const { variableType } = operand;
				if (!operatorConfig.otherOperandsVariableTypes.includes(variableType)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Le type de variable ${variableType} n'est pas autorisée pour l'opérateur ${operatorConfig.label[assignedVariableType] ?? operatorConfig.key}`,
						path: ["operands", 0, "variableType"],
					});
				}
			});
		}

		operands.forEach((operand, index) => {
			const { value, type, variableType } = operand;
			// If value is provided, validate that it matches the leftFieldType
			if (!isNullOrUndefined(value) && type !== OperandType.FIELD) {
				// TODO: this should be fixed
				const isValid = isVariableValueValid({
					value,
					type: variableType,
				});
				if (!isValid) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: `Le type de la valeur est invalide`,
						// TODO: check if this is the correct path
						path: ["operands", index, "value"],
					});
				}
			}
		});
	});

export type Operation = z.infer<typeof operationSchema>;
