import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useGetMenuList } from "@/hooks/useGetMenuList";
import { cn } from "@/utils";
import { Ellipsis, LogOut } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

interface MenuProps {
	isOpen: boolean | undefined;
}

const Menu = ({ isOpen }: MenuProps) => {
	const location = useLocation();
	const menuList = useGetMenuList(location.pathname);

	return (
		<ScrollArea className="[&>div>div[style]]:!block">
			<nav className="mt-8 h-full w-full">
				<ul className="flex min-h-[calc(100vh-48px-36px-16px-32px)] flex-col items-start space-y-1 px-2 lg:min-h-[calc(100vh-32px-40px-32px)]">
					{menuList.map(({ groupLabel, menus }, index) => (
						<li className={cn("w-full", groupLabel ? "pt-5" : "")} key={index}>
							{(isOpen && groupLabel) || isOpen === undefined ? (
								<p className="max-w-[248px] truncate px-4 pb-2 text-sm font-medium text-muted-foreground">
									{groupLabel}
								</p>
							) : !isOpen && isOpen !== undefined && groupLabel ? (
								<TooltipProvider>
									<Tooltip delayDuration={100}>
										<TooltipTrigger className="w-full">
											<div className="flex w-full items-center justify-center">
												<Ellipsis className="h-5 w-5" />
											</div>
										</TooltipTrigger>
										<TooltipContent side="right">
											<p>{groupLabel}</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							) : (
								<p className="pb-2"></p>
							)}
							{menus.map(({ href, label, icon: Icon, active }, index) => (
								<div className="w-full" key={index}>
									<TooltipProvider disableHoverableContent>
										<Tooltip delayDuration={100}>
											<TooltipTrigger asChild>
												<Button
													variant={active ? "secondary" : "ghost"}
													className="mb-1 h-10 w-full justify-start"
													asChild
												>
													<Link to={href}>
														<span
															className={cn(isOpen === false ? "" : "mr-4")}
														>
															<Icon size={18} />
														</span>
														<p
															className={cn(
																"max-w-[200px] truncate",
																isOpen === false
																	? "-translate-x-96 opacity-0"
																	: "translate-x-0 opacity-100",
															)}
														>
															{label}
														</p>
													</Link>
												</Button>
											</TooltipTrigger>
											{isOpen === false && (
												<TooltipContent side="right">{label}</TooltipContent>
											)}
										</Tooltip>
									</TooltipProvider>
								</div>
							))}
						</li>
					))}
					<li className="flex w-full grow items-end">
						<TooltipProvider disableHoverableContent>
							<Tooltip delayDuration={100}>
								<TooltipTrigger asChild>
									<Button
										onClick={() => {
											/* Implement sign out logic */
										}}
										variant="outline"
										className="mt-5 h-10 w-full justify-center"
									>
										<span className={cn(isOpen === false ? "" : "mr-4")}>
											<LogOut size={18} />
										</span>
										<p
											className={cn(
												"whitespace-nowrap",
												isOpen === false ? "hidden opacity-0" : "opacity-100",
											)}
										>
											Déconnexion
										</p>
									</Button>
								</TooltipTrigger>
								{isOpen === false && (
									<TooltipContent side="right">Déconnexion</TooltipContent>
								)}
							</Tooltip>
						</TooltipProvider>
					</li>
				</ul>
			</nav>
		</ScrollArea>
	);
};

export default Menu;
