import { OperationBuilder } from "@/components/subStep/operationBuilder/OperationBuilder";
import { Button } from "@/components/ui/button";
import { Sortable } from "@/components/ui/sortable";
import { getDefaultOperation } from "@/lib";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { pointerWithin } from "@dnd-kit/core";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import type { Operation, UpdateFinalStepDTO } from "@repos/rate-resolver-dtos";
import type { FinalStep } from "@repos/rate-resolver-shared";
import { updateFinalStepSchema } from "@repos/rate-resolver-dtos";

import { SubmitButton } from "../shared/SubmitButton";

export const OutputStepForm = ({
	defaultFinalStep,
}: {
	defaultFinalStep?: FinalStep;
}) => {
	const selectedProject = useAppStore((state) => state.selectedProject);
	const form = useForm<UpdateFinalStepDTO>({
		resolver: zodResolver(updateFinalStepSchema),
		defaultValues: {
			projectId: selectedProject?.id || "",
			operations: defaultFinalStep?.operations || [],
		},
		mode: "onSubmit",
	});

	const { mutate, isLoading } = trpc.steps.updateFinalStep.useMutation();

	const {
		fields: operations,
		append,
		remove,
		move,
	} = useFieldArray<UpdateFinalStepDTO>({
		control: form.control,
		name: `operations`,
	});

	const addOperation = () => {
		append(getDefaultOperation());
	};

	const onSubmit = (data: UpdateFinalStepDTO) => {
		if (!isLoading) {
			mutate(data);
		}
	};
	const onError = (errors: any) => {
		console.log("Error", errors);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit, onError)}>
				<div className="mb-4 rounded-xl border border-gray-300 bg-card p-4 text-card-foreground shadow">
					<Sortable
						orientation="mixed"
						collisionDetection={pointerWithin}
						value={operations}
						onMove={({ activeIndex, overIndex }) =>
							move(activeIndex, overIndex)
						}
						overlay={<div className="size-full rounded-md bg-card/10" />}
					>
						{operations.map((operation, indexOperation) => (
							<OperationBuilder
								key={operation.id}
								operation={operation as Operation}
								operationIndex={indexOperation}
								removeOperation={remove}
								basePath={"operations"}
							/>
						))}
					</Sortable>{" "}
				</div>
				<div className="mt-4 flex justify-end">
					<Button
						variant="outline"
						size="sm"
						onClick={addOperation}
						disabled={isLoading}
						type="button"
					>
						<Plus className="mr-2 h-4 w-4" />
						Ajouter une opération
					</Button>
				</div>
				<div className="mt-8 flex justify-end">
					<SubmitButton isLoading={isLoading} label="Enregistrer" />
				</div>
			</form>
		</FormProvider>
	);
};
