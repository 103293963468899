import { z } from "zod";

import { ConditionType } from "@repos/rate-resolver-shared";

import { conditionSchema } from "../primitives/conditionPrimitives";
import { operationSchema } from "../primitives/operationPrimitives";

export const subStepSchema = z.object({
	id: z.string().optional(),
	label: z.string().optional(),
	order: z.number().optional(),
	conditionType: z.nativeEnum(ConditionType).default(ConditionType.AND),
	conditions: z.array(conditionSchema).default([]),
	operations: z.array(operationSchema).default([]),
	fallbackOperations: z.array(operationSchema).default([]),
});

export type SubStepDTO = z.infer<typeof subStepSchema>;

export const subStepsUpsertSchema = z.object({
	stepId: z.string().uuid(),
	subSteps: z.array(subStepSchema),
});

export type SubStepsUpsertDTO = z.infer<typeof subStepsUpsertSchema>;

export const getSubStepsSchema = z.object({
	stepId: z.string().uuid(),
});

export type GetSubStepsDTO = z.infer<typeof getSubStepsSchema>;
