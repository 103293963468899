import type {
	Control,
	UseFormSetValue,
	UseFormStateReturn,
} from "react-hook-form";
import React, { memo, useState } from "react";
import CustomErrorTooltip from "@/components/shared/CustomErrorTooltip";
import { CustomSelect } from "@/components/shared/CustomSelect";
import { VariableSelect } from "@/components/subStep/variableSelect/VariableSelect";
import { Button } from "@/components/ui/button";
import { FormField, FormItem, FormMessage } from "@/components/ui/form";
import { SortableDragHandle, SortableItem } from "@/components/ui/sortable";
import useConditionOperationOptions from "@/hooks/useConditionOperationOptions";
import { useAppStore } from "@/stores/useAppStore";
import { ErrorMessage } from "@hookform/error-message";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { Trash2 } from "lucide-react";
import { useFormContext, useWatch } from "react-hook-form";

import type { SubStepsUpsertDTO } from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { ConditionType, isNullOrUndefined } from "@repos/rate-resolver-shared";

interface OperationItemProps {
	control: Control<SubStepsUpsertDTO>;
	setValue: UseFormSetValue<SubStepsUpsertDTO>;
	errors: UseFormStateReturn<SubStepsUpsertDTO>["errors"];
	condition: SubStepsUpsertDTO["subSteps"][0]["conditions"][0] & { id: string };
	conditionIndex: number;
	subStepIndex: number;
	shouldShowOperator: boolean;
	onDelete: () => void;
}
const operatorOptions = [
	{
		label: "ET",
		value: ConditionType.AND,
	},
	{
		label: "OU",
		value: ConditionType.OR,
	},
];

const ConditionRowFn: React.FC<OperationItemProps> = ({
	onDelete,
	condition,
	subStepIndex,
	conditionIndex,
	shouldShowOperator,
	control,
	setValue,
	errors,
}) => {
	const { initialLeftField, initialRightField } = useAppStore((state) => ({
		initialLeftField: state.getVariableByKey(condition.leftField),
		initialRightField: state.getVariableByKey(
			condition.rightField || undefined,
		),
	}));

	const operator = useWatch({
		name: `subSteps.${subStepIndex}.conditions.${conditionIndex}.operator`,
		control,
		defaultValue: undefined,
	});

	const rawValue = useWatch({
		name: `subSteps.${subStepIndex}.conditions.${conditionIndex}.value`,
		control,
		defaultValue: undefined,
	});

	const [selectedLeftField, setSelectedLeftField] = useState<
		SimpleVariable | null | undefined
	>(initialLeftField);

	const [selectedRightField, setSelectedRightField] = useState<
		SimpleVariable | null | undefined
	>(initialRightField);

	const operationOptions = useConditionOperationOptions(
		selectedLeftField?.type,
	);

	const { clearErrors } = useFormContext<SubStepsUpsertDTO>();

	return (
		<>
			<SortableItem value={condition.id} asChild>
				<div className="flex flex-col">
					<div className="flex items-center justify-between space-x-2">
						<div className="flex flex-row gap-2">
							<div className="flex flex-col">
								<ErrorMessage
									errors={errors}
									name={`subSteps.${subStepIndex}.conditions.${conditionIndex}.leftField`}
									render={({ message }) => (
										<CustomErrorTooltip message={message} />
									)}
								/>
								<VariableSelect
									selectedItem={selectedLeftField}
									onSelect={(variable) => {
										const prevType = selectedLeftField?.type;

										setValue(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.leftField`,
											variable.key,
											{ shouldValidate: true },
										);
										setValue(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.leftFieldType`,
											variable.type,
											{ shouldValidate: true },
										);
										setSelectedLeftField(variable);

										if (prevType !== variable.type) {
											setSelectedRightField(null);
											setValue(
												`subSteps.${subStepIndex}.conditions.${conditionIndex}.rightField`,
												undefined,
											);
											setValue(
												`subSteps.${subStepIndex}.conditions.${conditionIndex}.operator`,
												//@ts-expect-error this should throw a zod error
												undefined,
											);
											setValue(
												`subSteps.${subStepIndex}.conditions.${conditionIndex}.value`,
												undefined,
											);
										}
									}}
								/>
							</div>
							<div className="w-34 flex-col">
								<FormField
									control={control}
									name={`subSteps.${subStepIndex}.conditions.${conditionIndex}.operator`}
									render={({ field }) => (
										<FormItem className="flex min-w-32 flex-col space-y-0">
											<ErrorMessage
												errors={errors}
												name={`subSteps.${subStepIndex}.conditions.${conditionIndex}.operator`}
												render={({ message }) => (
													<CustomErrorTooltip message={message} />
												)}
											/>
											<CustomSelect
												placeholder="Choisir une operation"
												disabled={operationOptions.disabled}
												options={operationOptions.formattedOperations}
												value={operator}
												onValueChange={field.onChange}
												className="w-full"
												isForm
											/>
										</FormItem>
									)}
								/>
							</div>
							<div className="flex flex-col">
								<ErrorMessage
									errors={errors}
									name={`subSteps.${subStepIndex}.conditions.${conditionIndex}.rightField`}
									render={({ message }) => (
										<CustomErrorTooltip message={message} />
									)}
								/>
								<ErrorMessage
									errors={errors}
									name={`subSteps.${subStepIndex}.conditions.${conditionIndex}.value`}
									render={({ message }) => (
										<CustomErrorTooltip message={message} />
									)}
								/>
								<VariableSelect
									selectedItem={selectedRightField}
									variableTypes={
										isNullOrUndefined(selectedLeftField?.type)
											? undefined
											: [selectedLeftField.type]
									}
									onSelect={(variable) => {
										setSelectedRightField(variable);
										setValue(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.rightField`,
											variable.key,
										);
										clearErrors(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.rightField`,
										);
									}}
									disabled={!operator}
									allowRawValue
									rawValue={rawValue}
									onRawValueSubmit={(value) => {
										setSelectedRightField(null);
										setValue(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.rightField`,
											undefined,
										);
										setValue(
											`subSteps.${subStepIndex}.conditions.${conditionIndex}.value`,
											value,
										);
									}}
								/>
							</div>
						</div>
						<div className="flex flex-row gap-2">
							<SortableDragHandle
								variant="default"
								size="icon"
								className="size-8"
							>
								<DragHandleDots2Icon className="size-4" aria-hidden="true" />
							</SortableDragHandle>

							<Button
								variant="ghost"
								size="icon"
								className="text-destructive hover:bg-red-200 hover:text-destructive"
								onClick={onDelete}
								type="button"
							>
								<Trash2 className="h-4 w-4 " />
							</Button>
						</div>
					</div>
				</div>
			</SortableItem>
			{shouldShowOperator && (
				<FormField
					name={`subSteps.${subStepIndex}.conditionType`}
					control={control}
					render={({ field }) => (
						<FormItem>
							<CustomSelect
								placeholder="Selectionner un type de variable"
								options={operatorOptions}
								value={field.value}
								onValueChange={field.onChange}
								className="mb-2 h-6 w-16 border-none bg-slate-200 dark:border-input dark:bg-transparent"
								isForm
							/>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}
		</>
	);
};

export const ConditionRow = memo(ConditionRowFn);
