import { z } from "zod";

export const createProjectSchema = z.object({
	name: z
		.string()
		.min(3, "Veuillez entrer au moins trois caractères")
		.max(50, "Veuillez entrer au plus 50 caractères"),
	description: z
		.string()
		.min(3, "Veuillez entrer au moins trois caractères")
		.max(255, "Veuillez entrer au plus 500 caractères"),
	image: z.string().url().optional(),
});

export type CreateProjectDTO = z.infer<typeof createProjectSchema>;

export const updateProjectSchema = z.object({
	id: z.string().uuid(),
	name: z
		.string()
		.min(3, "Veuillez entrer au moins trois caractères")
		.max(50, "Veuillez entrer au plus 50 caractères")
		.optional(),
	description: z
		.string()
		.max(255, "Veuillez entrer au plus 500 caractères")
		.optional(),
	image: z.string().url().optional(),
});

export type UpdateProjectDTO = z.infer<typeof updateProjectSchema>;

export type ProjectDTO = z.infer<typeof updateProjectSchema>;

export const deleteProjectSchema = z.object({
	id: z.string().uuid(),
});

export type DeleteProjectDTO = z.infer<typeof deleteProjectSchema>;
