import type { Column, ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { trpc } from "@/utils";
import { ArrowUpDown } from "lucide-react";

import type { SimpleVariable } from "@repos/rate-resolver-shared";

import { VariableActions } from "../subStep/variableSelect/VariableActions";

interface GetColumnsProps {
	headers: { [key: string]: string };
	refetchVariables: () => void;
}

export const getColumns = ({
	headers,
	refetchVariables,
}: GetColumnsProps): ColumnDef<SimpleVariable>[] => {
	return [
		...Object.keys(headers).map((key) => ({
			accessorKey: key,
			header: ({ column }: { column: Column<SimpleVariable> }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					{headers[key]}
					<ArrowUpDown className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }: { row: Row<SimpleVariable> }) => (
				<div>{String(row.getValue(key))}</div>
			),
		})),
		{
			id: "actions",
			cell: ({ row }) => {
				const { id } = row.original;

				const deleteVariableMutation =
					trpc.variables.deleteVariable.useMutation({
						onSuccess: () => {
							refetchVariables();
							console.log(`Variable with ID: ${id} deleted successfully.`);
						},
						onError: (error) => {
							console.error("Failed to delete the variable:", error);
						},
					});

				const handleDelete = () => {
					deleteVariableMutation.mutate({ id });
				};

				return (
					<VariableActions
						variableId={id}
						onEdit={refetchVariables}
						onDelete={handleDelete}
						iconOrientation="horizontal"
					/>
				);
			},
		},
	];
};
