import type { Step } from "@/types";
import type { SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAppStore } from "@/stores/useAppStore";
import { cn, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pencil, Save } from "lucide-react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const labelSchema = z.object({
	label: z
		.string({
			required_error: "Le nom de l'étape est obligatoire",
			invalid_type_error:
				"Le nom de l'étape doit être une chaîne de caractères",
		})
		.min(3, "Veuillez entrer au moins trois caractères"),
});

type LabelDTO = z.infer<typeof labelSchema>;

type SubStepTitleProps = {
	step: Step;
};

export const SubStepTitle = ({ step }: SubStepTitleProps) => {
	const { selectedProject, setCurrentStep } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
		setCurrentStep: state.setCurrentStep,
	}));

	const { mutateAsync: updateStep } = trpc.steps.updateStep.useMutation({
		onSuccess() {
			setIsEditMode(false);
		},
	});
	const form = useForm<LabelDTO>({
		resolver: zodResolver(labelSchema),
		defaultValues: {
			label: step.label,
		},
	});
	const [isEditMode, setIsEditMode] = useState(false);

	const utils = trpc.useUtils();

	const onSubmitStepName: SubmitHandler<LabelDTO> = async (data) => {
		if (!step.id) return;

		try {
			const updatedStep = await updateStep({
				id: step.id,
				label: data.label,
				order: step.order,
			});
			setCurrentStep(updatedStep);

			if (selectedProject) {
				utils.steps.getSteps.invalidate({ projectId: selectedProject.id });
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<FormProvider {...form}>
			<div className="flex w-full items-center justify-center">
				<form onSubmit={form.handleSubmit(onSubmitStepName)}>
					<div className="flex w-full max-w-sm items-center justify-center space-x-2">
						{isEditMode ? (
							<FormField
								control={form.control}
								name="label"
								render={({ field }) => (
									<FormItem>
										<FormControl autoFocus>
											<Input
												placeholder="Nom de l'étape"
												className={`text-2xl font-medium text-teal-500 `}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						) : (
							<Label className="text-2xl font-medium text-teal-500">
								{step.label}
							</Label>
						)}
						<Button
							variant="outline"
							size={"icon"}
							type="button"
							className={cn(
								"flex-shrink-0 border-none",
								isEditMode && "bg-primary text-white",
								!isEditMode && "bg-primary text-white",
							)}
							onClick={(e) => {
								e.preventDefault();
								if (!isEditMode) {
									setIsEditMode(true);
								} else {
									form.handleSubmit(onSubmitStepName)();
								}
							}}
						>
							{isEditMode ? (
								<Save className="h-5 w-5 " />
							) : (
								<Pencil className="h-5 w-5 " />
							)}
						</Button>
					</div>
				</form>
			</div>
		</FormProvider>
	);
};
