import type {
	FieldArrayPath,
	Path,
	UseFieldArrayRemove,
} from "react-hook-form";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Operator } from "@/components/subStep/operationBuilder/Operator";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { SortableDragHandle, SortableItem } from "@/components/ui/sortable";
import useOperationOptions from "@/hooks/useOperationOptions";
import { getDefaultOperation } from "@/lib";
import { useAppStore } from "@/stores/useAppStore";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { Plus, Trash2 } from "lucide-react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import type {
	Operand as OperandDTO,
	Operation,
	SubStepsUpsertDTO,
	UpdateFinalStepDTO,
} from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import {
	getAllowedFirstOperandVariableTypes,
	getAllowedOtherOperandVariableTypes,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

import { VariableSelect } from "../variableSelect/VariableSelect";
import { Operand } from "./Operand";

interface OperationBuilderProps {
	operationIndex: number;
	operation: Operation;
	basePath: Path<SubStepsUpsertDTO> | Path<UpdateFinalStepDTO>;
	removeOperation: UseFieldArrayRemove;
}
export function OperationBuilder({
	operationIndex,
	basePath,
	removeOperation,
	operation,
}: OperationBuilderProps) {
	const { control, setValue } = useFormContext<SubStepsUpsertDTO>();

	const { getVariableByKey } = useAppStore((state) => ({
		getVariableByKey: state.getVariableByKey,
	}));
	useEffect(() => {
		if (operation.assignedVariable) {
			setAssignedVariable(getVariableByKey(operation.assignedVariable));
		}
	}, [getVariableByKey, operation]);

	const [assignedVariable, setAssignedVariable] = useState<
		SimpleVariable | null | undefined
	>(undefined);

	const {
		fields: operandsFields,
		replace,
		append,
		remove,
	} = useFieldArray<SubStepsUpsertDTO>({
		control,
		name: `${basePath}.${operationIndex}.operands` as FieldArrayPath<SubStepsUpsertDTO>,
	});

	const firstOperandType = useWatch({
		control,
		name: `${basePath}.${operationIndex}.operands.0.variableType` as Path<SubStepsUpsertDTO>,
		defaultValue: undefined,
	}) as OperandDTO["variableType"] | undefined;

	const lastOperand = useWatch({
		control,
		name: `${basePath}.${operationIndex}.operands.${operandsFields.length - 1}` as Path<SubStepsUpsertDTO>,
		defaultValue: undefined,
	}) as OperandDTO | undefined;

	const selectedOperation = useWatch({
		control,
		name: `${basePath}.${operationIndex}.operationType` as Path<SubStepsUpsertDTO>,
		defaultValue: undefined,
	}) as Operation["operationType"] | undefined;

	const operationsOptions = useOperationOptions(
		assignedVariable?.type,
		firstOperandType,
	);

	const allowedFirstOperandTypes = assignedVariable
		? getAllowedFirstOperandVariableTypes(assignedVariable.type)
		: [];
	const allowedOtherOperandTypes =
		!isNullOrUndefined(assignedVariable) &&
		!isNullOrUndefined(selectedOperation) &&
		!isNullOrUndefined(firstOperandType)
			? getAllowedOtherOperandVariableTypes(
					assignedVariable.type,
					firstOperandType,
					selectedOperation,
				)
			: [];

	const isDisabled = useCallback(
		(index) => {
			if (index === 0) {
				return isNullOrUndefined(assignedVariable);
			} else if (index === 1) {
				return isNullOrUndefined(selectedOperation);
			} else {
				return false;
			}
		},
		[assignedVariable, selectedOperation],
	);

	const showAddOperand = useMemo(() => {
		if (
			assignedVariable?.type === "BOOLEAN" &&
			!isNullOrUndefined(lastOperand?.value)
		) {
			return operandsFields.length < 2;
		}
		return Boolean(lastOperand?.value);
	}, [assignedVariable, operandsFields, lastOperand]);

	return (
		<SortableItem value={operation.id} asChild>
			<div className="my-2 flex w-full items-center justify-between gap-10">
				<div className="flex items-center gap-8 ">
					<VariableSelect
						selectedItem={assignedVariable}
						onSelect={(variable) => {
							const prevType = assignedVariable?.type;

							setValue(
								`${basePath}.${operationIndex}.assignedVariable` as Path<SubStepsUpsertDTO>,
								variable.key,
							);
							setValue(
								`${basePath}.${operationIndex}.assignedVariableType` as Path<SubStepsUpsertDTO>,
								variable.type,
							);

							setAssignedVariable(variable);

							if (!isNullOrUndefined(prevType) && prevType !== variable.type) {
								replace([getDefaultOperation(), getDefaultOperation()]);
							}
						}}
					/>
					<h4>=</h4>
					<div className="w-full">
						<Card className="flex w-full flex-wrap items-center gap-2 rounded-xl border border-gray-300 bg-card p-4 text-card-foreground shadow">
							{operandsFields.map((operand, operandIndex) => {
								return (
									<Fragment key={operand.id}>
										<Operand
											assignedVariableType={assignedVariable?.type}
											operand={operand as OperandDTO}
											operandIndex={operandIndex}
											basePath={basePath}
											operationIndex={operationIndex}
											removeOperation={remove}
											totalOperands={operandsFields.length}
											isOperandDisabled={isDisabled(operandIndex)}
											allowedVariableTypes={
												operandIndex === 0
													? allowedFirstOperandTypes
													: allowedOtherOperandTypes
											}
											resetOperands={() => replace([getDefaultOperation()])}
										/>
										{operandIndex !== operandsFields.length - 1 && (
											<Operator
												operationsOptions={operationsOptions}
												operationIndex={operationIndex}
												basePath={basePath}
											/>
										)}
									</Fragment>
								);
							})}
							{showAddOperand ? (
								<Button
									variant="outline"
									size="sm"
									type="button"
									className="py-4"
									disabled={
										(!isNullOrUndefined(assignedVariable) &&
											assignedVariable.type === "BOOLEAN" &&
											operandsFields.length === 2) ||
										isNullOrUndefined(lastOperand?.value)
									}
									onClick={() => {
										append(getDefaultOperation());
									}}
								>
									<Plus className="size-5" />
								</Button>
							) : null}
						</Card>
					</div>
				</div>
				<div className="flex flex-row gap-2">
					<SortableDragHandle variant="default" size="icon" className="size-8">
						<DragHandleDots2Icon className="size-4" aria-hidden="true" />
					</SortableDragHandle>
					<Button
						variant="ghost"
						size="icon"
						type="button"
						className="text-destructive hover:bg-red-200 hover:text-destructive"
						onClick={() => removeOperation(operationIndex)}
					>
						<Trash2 className="size-4 text-destructive" />
					</Button>
				</div>
			</div>
		</SortableItem>
	);
}
