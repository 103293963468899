import type { LucideIcon } from "lucide-react";
import { useAppStore } from "@/stores/useAppStore";
import {
	ChevronLast,
	FileDigitIcon,
	LayoutGrid,
	Notebook,
	Settings,
	Variable,
} from "lucide-react";

type Menu = {
	href: string;
	label: string;
	active: boolean;
	icon: LucideIcon;
};

type Group = {
	groupLabel: string;
	menus: Menu[];
};

export function useGetMenuList(pathname: string): Group[] {
	const { selectedProject } = useAppStore();

	const menuList: Group[] = [
		{
			groupLabel: "",
			menus: [
				{
					href: "/projets",
					label: "Projets",
					active: pathname.includes("/projets"),
					icon: LayoutGrid,
				},
			],
		},
	];

	if (selectedProject) {
		menuList.push({
			groupLabel: selectedProject.name,
			menus: [
				{
					href: "/projet/variables",
					label: "Variables",
					active: pathname.includes("/projet/variables"),
					icon: Variable,
				},
				{
					href: "/projet/etapes",
					label: "Etapes",
					active: pathname.includes("/projet/etapes"),
					icon: FileDigitIcon,
				},
				{
					href: "/projet/output",
					label: "Output",
					active: pathname.includes("/projet/output"),
					icon: ChevronLast,
				},
				{
					href: "/projet/tarification",
					label: "Tarification",
					active: pathname.includes("/projet/tarification"),
					icon: Notebook,
				},
			],
		});
	}

	menuList.push({
		groupLabel: "Paramètres",
		menus: [
			{
				href: "#",
				label: "Profile",
				active: pathname.includes("#"),
				icon: Settings,
			},
		],
	});

	return menuList;
}
