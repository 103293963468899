import { VariableType } from "@prisma/client";
import { z } from "zod";

import {
	AllConditionOperators,
	getConditionOperators,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

import { isVariableValueValid } from "./variablePrimitives";

export const conditionSchema = z
	.object({
		id: z.string().uuid(),
		leftField: z.string({
			required_error: "Veuillez sélectionner une variable",
			invalid_type_error: "Veuillez sélectionner une variable",
		}),
		leftFieldType: z.nativeEnum(VariableType),
		operator: z.nativeEnum(AllConditionOperators, {
			required_error: "Veuillez sélectionner une opération",
			invalid_type_error: "Veuillez sélectionner une opération",
		}),
		rightField: z.string().nullable().optional(),
		value: z
			.union([z.string(), z.number(), z.boolean(), z.date()])
			.nullable()
			.optional(),
	})
	.superRefine((data, ctx) => {
		const { rightField, value, leftFieldType } = data;
		// Check that at least one of rightField or value is provided
		if (isNullOrUndefined(rightField) && isNullOrUndefined(value)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Veuillez renseigner une valeur",
				path: ["rightField"],
			});
		}
		// If value is provided, validate that it matches the leftFieldType
		if (!isNullOrUndefined(value)) {
			const isValid = isVariableValueValid({
				value,
				type: leftFieldType,
			});
			if (!isValid) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Le type de la valeur est invalide`,
					path: ["value"],
				});
			}
		}
		// validate condition operator
		const possibleOperators = getConditionOperators(data.leftFieldType);
		if (!possibleOperators.includes(data.operator)) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: `L'opérateur ${data.operator} n'est pas valide pour les types de variables ${data.leftFieldType}`,
				path: ["operator"],
			});
		}
	});

export type Condition = z.infer<typeof conditionSchema>;
